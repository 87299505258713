<template>
    <div id="compontent">
            <dv-border-box-10 width="85%" backgroundColor="RGBA(77, 144, 254,0.4)">
                <div style="height:95%;width:95%;display:flex;flex-direction:column;justify-content: space-around;">
                    <div class="btns">
                        <div class="btn" @click="clickItem('SW')" :class="{haveChoosen:flag=='SW'}">
                        <dv-border-box-7 class="btn-item">
                            <img src="../../../public/imgs/SW.png" alt="">
                            <div class="item-text">
                                <div style="font-weight:bold">生物医药</div>
                                <div><span class="text-num">2845</span>件</div>
                            </div>
                        </dv-border-box-7>
                        </div>
                         <div class="btn" @click="clickItem('NY')" :class="{haveChoosen:flag=='NY'}">
                        <dv-border-box-7 class="btn-item"> 
                            <img src="../../../public/imgs/NY.png" alt="">
                             <div class="item-text">
                                <div style="font-weight:bold">能源化工</div>
                                <div><span class="text-num">10947</span>件</div>
                            </div>
                        </dv-border-box-7>
                        </div>
                         <div class="btn" @click="clickItem('JG')" :class="{haveChoosen:flag=='JG'}">
                        <dv-border-box-7 class="btn-item">
                            <img src="../../../public/imgs/JG.png" alt="">
                             <div class="item-text">
                                <div style="font-weight:bold">结构材料</div>
                                <div><span class="text-num">6880</span>件</div>
                            </div>
                        </dv-border-box-7>
                        </div>
                         <div class="btn" @click="clickItem('ZB')" :class="{haveChoosen:flag=='ZB'}">
                        <dv-border-box-7 class="btn-item">
                            <img src="../../../public/imgs/ZB.png" alt="">
                             <div class="item-text">
                                <div style="font-weight:bold">装备制造</div>
                                <div><span class="text-num">51489</span>件</div>
                            </div>
                        </dv-border-box-7>
                        </div>
                        <div class="btn" @click="clickItem('FZ')" :class="{haveChoosen:flag=='FZ'}">
                        <dv-border-box-7 class="btn-item">
                            <img src="../../../public/imgs/FZ.png" alt="">
                             <div class="item-text">
                                <div style="font-weight:bold">纺织服装</div>
                                <div><span class="text-num">1430</span>件</div>
                            </div>
                        </dv-border-box-7>
                        </div>
                    </div>
                    <div class="title">
                        <dv-decoration-7 style="width:100%;height:100%;">{{data.name}}产业授权专利分布情况</dv-decoration-7>
                    </div>
                    <div class="map">
                        <map-echart id="mapChartB" :mapData="mapData" :data="needData.mapData" :isChange="true" mapName="xinjiang" :options="options" style="height:100%;width:100%"/>
                    </div>
                </div>
            </dv-border-box-10>
    </div>
</template>
<script>
import mapEchart from '../../components/echarts/mapEchart.vue';
import mapData from '../../../public/mapData/wulumuqiMap.json'
export default {
  components: { mapEchart },
    name:"IMapData",
    props:['data'],
    data() {
        return {
            flag:"",
            mapData:"",
            needData:"",
            options:"",
            titleObj:{
              SW:"生物医药",
              NY:"能源化工",
              JG:"结构材料",
              ZB:"装备制造",
              FZ:"纺织服装"
            }
        }
    },
    mounted(){
        let me=this
        this.flag='SW'
       this.mapData=mapData
    },
    methods:{
        clickItem(e){
            this.flag=e
        }
    },
    watch:{
        flag:{
            handler:function(val){
                this.$emit('clickItem',this.flag)
            },
        immediate:true
        },
        data:{
            handler:function(vla){
                this.needData=this.data
                this.options={
        tooltip: {
          trigger: "item",
          //鼠标放上去显示的数据和文字
          transitionDuration: 0,
          confine: true,
          formatter:(parms)=>{
            // console.log(parms)
            return parms.data.name+"<br/>"+this.titleObj[this.flag]+"：<span style='color:rgba(255, 82, 17,1);font-weight:bold;font-size:0.2rem'>"+parms.data.value+"</span>件"
          },
          backgroundColor: "rgba(0,0,0,0.6)",
          textStyle:{
            color:"rgba(255,255,255,1)"
          }
        },
        visualMap: {
          show: false,
          min: 0,
          max: 200,
          text: ["High", "Low"],
          realtime: false,
          calculable: false,
          inRange: {
            color: ["lightskyblue", "yellow", "orangered"],
          },
        },
        // //在地图旁显示的数据
        series: [
          {
            name: "类型一",
            type: "map",
            mapType: "xinjiang",
            roam: false,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  textStyle: {
                    color: "rgba(0,0,0,0.5)",
                    fontSize:"0.15rem"
                  },
                },
                borderColor: "#FFF", //边界线颜色
                borderWidth: 1, //边界线大小
                shadowColor: "rgba(255,255,255,0)",
                shadowBlur: 20,
                // areaColor: "rgb(21, 41, 77)" //地区背景颜色
              },
              emphasis: {
                //选中或者悬浮状态
                label: {
                  show: true,
                  textStyle: {
                    color: "rgba(0,0,0,1)", //选中或者悬浮字体颜色
                    fontSize:'0.2rem'
                  },
                },
                areaColor: "#2b91b7", //选中或者悬浮区域颜色
              },
            },
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: true,
              },
            },
            data: this.needData.mapData,
          },
          {
            name: "Top 5",
            type: "effectScatter",
            coordinateSystem: "geo",
          },
        ],
      }
            }
        }
    }
}
</script>
<style scoped>
    #compontent{
        width: 100%;
        height: 100%;
    }
    .btns{
        height: 12%;
        width: 100%;
        display: flex;
        justify-content: space-around;
        /* background-color: blanchedalmond; */
    }
    .title{
        height: 8%;
        font-size:.325rem /* 26px -> .325rem */;
        font-weight: bold;
    }
    .map{
        height: 75%;
        width: 100%;
    }
    .btn{
        width: 18%;
        cursor: pointer;
    }
    .btn img{
        width: 0.3125rem /* 40px -> .5rem */;
        height: .3125rem /* 40px -> .5rem */;
        margin-right:.0625rem /* 5px -> .0625rem */;
    }
    .btn-item{
        display: flex;
        justify-content: space-around;
    }
    .item-text{
        font-size: .175rem /* 14px -> .175rem */;
        line-height: .25rem /* 20px -> .25rem */;
        text-align: left;
    }
    .text-num{
        font-size: .2125rem /* 17px -> .2125rem */;
        font-weight: bold;
        color: cyan;
    }
    .haveChoosen{
        background-color: rgba(16, 240, 240,0.3);
    }
</style>