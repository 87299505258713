<template>
  <div id="compontent">
    <dv-border-box-2 backgroundColor="RGBA(77, 144, 254,0.2)">
      <div style="width: 95%; height: 90%" class="out">
        <div class="title">
          <span class="fixed-yellow">各领</span>域领先机构排名
        </div>
        <div class="btns">
          <div class="btn" @click="clickItem(1)" :class="{btnChoosen:1==flag}">企业</div>
          <div class="btn" @click="clickItem(2)" :class="{btnChoosen:2==flag}">大专院校</div>
          <div class="btn" @click="clickItem(3)" :class="{btnChoosen:3==flag}">科研机构</div>
          <div class="btn" @click="clickItem(4)" :class="{btnChoosen:4==flag}">机关团队</div>
        </div>
        <div class="content">
          <dv-scroll-board :config="config" style="width: 100%; height: 100%" />
        </div>
      </div>
    </dv-border-box-2>
  </div>
</template>
<script>
import data from "../../../public/jsData/innovate";
export default {
  data() {
    return {
      config: {
        header: ["列1", "列2", "列3", "lie"],
        data: [],
        align: ["center", "left", "center", "center"],
      },
      needData: "",
      tableData_not: "",
      flag: 1,
    };
  },
  mounted() {
    this.needData = data;
    this.tableData_not = this.needData["tableItems1_" + this.flag];
  },
  methods: {
    clickItem(flag) {
      this.flag = flag;
    },
  },
  watch: {
    flag: {
      handler: function (val) {
        this.tableData_not = this.needData["tableItems1_" + val];
      },
    },
    tableData_not: {
      handler: function (val) {
        let data = [];
        for (let i = 0; i < this.tableData_not.length; i++) {
          data.push([
            '<span style="font-size:0.1875rem">' +
              this.tableData_not[i].Ranking +
              "</span>",
            '<span style="font-size:0.1875rem">' +
              this.tableData_not[i].Icp +
              "</span>",
            '<span style="font-size:0.1875rem">' +
              this.tableData_not[i].Name +
              "</span>",
            '<span style="font-size:0.1875rem">' +
              this.tableData_not[i].Num +
              "</span>",
          ]);
          if (this.tableData_not[i].Ranking == "01") {
            data[i] = [
              '<span style="font-size:0.2rem;color:yellow;font-weight:bold">' +
                this.tableData_not[i].Ranking +
                "</span>",
              '<span style="font-size:0.2rem;color:yellow;font-weight:bold">' +
                this.tableData_not[i].Icp +
                "</span>",
              '<span style="font-size:0.2rem;color:yellow;font-weight:bold">' +
                this.tableData_not[i].Name +
                "</span>",
              '<span style="font-size:0.2rem;color:yellow;font-weight:bold">' +
                this.tableData_not[i].Num +
                "</span>",
            ];
          }
        }
        this.config = {
          header: [
            "<span style='font-size:0.2rem'>排名</span>",
            "<span style='font-size:0.2rem'>ICP号</span>",
            "<span style='font-size:0.2rem'>机构名称</span>",
            "<span style='font-size:0.2rem'>授权量</span>",
          ],
          data: data,
          align: ["center", "center", "center", "center"],
          columnWidth: [50, 250, 200, 100],
          oddRowBGC: "RGBA(77, 144, 254,0)",
          evenRowBGC: "RGBA(77, 144, 254,0.1)",
          headerBGC: "RGBA(77, 144, 254,0.5)",
        };
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 100%;
  /* background-color: brown; */
}
.out {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.title {
  height: 10%;
  font-size: 0.2rem /* 16px -> .2rem */;
  font-weight: bold;
  line-height: 0.325rem /* 26px -> .325rem */;
  /* background-color: brown; */
}
.btns {
  height: 13%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* background-color: burlywood; */
}
.btn {
    color: #bdbdbd;
  font-weight: bold;
  font-size: 0.1875rem /* 15px -> .1875rem */;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: chocolate; */
  width: 1.25rem /* 100px -> 1.25rem */;
  height: 0.375rem /* 30px -> .375rem */;
  border: .025rem /* 2px -> .025rem */ solid #bdbdbb;
  cursor: pointer;
}
.content {
  height: 75%;
}
.btnChoosen{
    color: #FFF;
    border: none;
    background-color:rgba(13, 72, 92,1) ;
   text-shadow: 0 0 0px #FFF,0 0 0px #FFF,0 0 .025rem /* 2px -> .025rem */ #FFF;
}
</style>