<template>
  <div id="compontent">
    <dv-border-box-2 backgroundColor="RGBA(77, 144, 254,0.2)">
      <div style="width: 95%; height: 95%" class="out">
        <div class="title"><span class="fixed-yellow">专利</span>营运情况</div>
        <div class="content">
          <div class="item leftTop">
            <div class="itemTitle">
              企业：<span class="itemNum" style="color: rgba(132, 106, 243, 1)"
                >10476 </span
              >次
            </div>
            <div class="itemContent">
              <dv-active-ring-chart
                :config="configA"
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
          <div class="item rightTop">
                <div class="itemTitle">
              大专院校：<span class="itemNum" style="color:rgba(255, 204, 0, 1)"
                >604 </span
              >次
            </div>
            <div class="itemContent">
              <dv-active-ring-chart
                :config="configB"
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
          <div class="item leftBottom">
                <div class="itemTitle">
              科研机构：<span class="itemNum" style="color: rgba(1, 255, 255, 1)"
                >34 </span
              >次
            </div>
            <div class="itemContent">
              <dv-active-ring-chart
                :config="configC"
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
          <div class="item rightBottom">
                <div class="itemTitle">
              机关团体：<span class="itemNum" style="color: rgba(31, 131, 246, 1)"
                >571 </span
              >次
            </div>
            <div class="itemContent">
              <dv-active-ring-chart
                :config="configD"
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
        </div>
      </div>
    </dv-border-box-2>
  </div>
</template>
<script>
import data from "../../../public/jsData/innovate";
export default {
  data() {
    return {
      needData: "",
      configA: {},
      configB:{},
      configC:{},
      configD:{}
    };
  },
  mounted() {
    this.needData = data;
  },
  watch: {
    needData: {
      handler: function (val) {
        this.configA = {
          data: this.needData.qiyevalue,
          showOriginValue: true,
          digitalFlopStyle: {
            fontSize: "20",
          },
          radius: "70%",
          activeRadius: "75%",
          color:['#0f4fa8','#ffca00','#ff6200']
        };
          this.configB = {
          data: this.needData.dazhuanvalue,
          showOriginValue: true,
          digitalFlopStyle: {
            fontSize: "20",
          },
          radius: "70%",
          activeRadius: "75%",
          color:['#0f4fa8','#ffca00','#ff6200']
        };
          this.configC = {
          data: this.needData.keyanvalue,
          showOriginValue: true,
          digitalFlopStyle: {
            fontSize: "20",
          },
          radius: "70%",
          activeRadius: "75%",
          color:['#0f4fa8','#ffca00','#ff6200']
        };
          this.configD = {
          data: this.needData.jiguanvalue,
          showOriginValue: true,
          digitalFlopStyle: {
            fontSize: "20",
          },
          radius: "70%",
          activeRadius: "75%",
          color:['#0f4fa8','#ffca00','#ff6200']
        };
      },
    },
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 98%;
  /* background-color: coral; */
}
.out {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.title {
  height: 8%;
  width: 100%;
  /* background-color: cornflowerblue; */
  font-size: 0.2rem /* 16px -> .2rem */;
  font-weight: bold;
  line-height: 0.5rem /* 40px -> .5rem */;
}
.content {
  /* background-color: cornflowerblue; */
  height: 90%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.item {
  width: 48%;
  height: 48%;
  background-color: cornsilk;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.itemTitle {
  height: 15%;
  /* background-color: royalblue; */
  font-size: 0.175rem /* 14px -> .175rem */;
  line-height: 0.625rem /* 50px -> .625rem */;
  text-align: center;
}
.itemNum {
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
}
.itemContent {
  height: 80%;
}
.leftTop {
  background-color: rgba(132, 106, 243, 0.1);
}
.rightTop {
  background-color: rgba(255, 204, 0, 0.1);
}
.leftBottom {
  background-color: rgba(1, 255, 255, 0.1);
}
.rightBottom {
  background-color: rgba(31, 131, 246, 0.1);
}
</style>