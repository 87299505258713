<template>
  <div id="compontent">
    <dv-border-box-10 width="85%" backgroundColor="RGBA(77, 144, 254,0.4)">
      <div
        style="
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
          align-items: center;
        "
      >
        <div id="echart" style="width: 100%; height: 75%">
          <map-echart
            id="mapEchartA"
            :options="options"
            :data="data_"
            :mapData="mapData"
            mapName="xinjiang"
            :isChange="true"
            style="width: 100%; height: 100%"
          ></map-echart>
        </div>
        <div id="btns">
          <dv-border-box-7 class="border" v-show="!(flag == 'mapData')">
            <div class="borderContent" @click="clickItem('mapData')">
              <img src="../../../public/imgs/zhuanli.png" alt="" />
              <div class="text">
                <div class="fixed">专利授权量</div>
                <div class="fixed"><span class="fixedNum">25149</span>件</div>
              </div>
            </div>
          </dv-border-box-7>
          <dv-border-box-8 class="border borderA" v-show="flag == 'mapData'">
            <div class="borderContent" @click="clickItem('mapData')">
              <img src="../../../public/imgs/zhuanli.png" alt="" />
              <div class="text">
                <div class="fixed">专利授权量</div>
                <div class="fixed"><span class="fixedNum">25149</span>件</div>
              </div>
            </div>
          </dv-border-box-8>

          <dv-border-box-7 class="border" v-show="!(flag == 'mapDataSB')">
            <div class="borderContent" @click="clickItem('mapDataSB')">
              <img src="../../../public/imgs/shangbiao.png" alt="" />
              <div class="text">
                <div class="fixed">商标注册量</div>
                <div class="fixed"><span class="fixedNum">112104</span>件</div>
              </div>
            </div>
          </dv-border-box-7>
          <dv-border-box-8 class="border borderA" v-show="flag == 'mapDataSB'">
            <div class="borderContent" @click="clickItem('mapDataSB')">
              <img src="../../../public/imgs/shangbiao.png" alt="" />
              <div class="text">
                <div class="fixed">商标注册量</div>
                <div class="fixed"><span class="fixedNum">112104</span>件</div>
              </div>
            </div>
          </dv-border-box-8>

          <dv-border-box-7 class="border" v-show="!(flag == 'mapDataDB')">
            <div class="borderContent" @click="clickItem('mapDataDB')">
              <img src="../../../public/imgs/dili.png" alt="" />
              <div class="text">
                <div class="fixed">地理标志</div>
                <div class="fixed"><span class="fixedNum">3</span>个</div>
              </div>
            </div>
          </dv-border-box-7>
          <dv-border-box-8 class="border borderA" v-show="flag == 'mapDataDB'">
            <div class="borderContent" @click="clickItem('mapDataDB')">
              <img src="../../../public/imgs/dili.png" alt="" />
              <div class="text">
                <div class="fixed">地理标志</div>
                <div class="fixed"><span class="fixedNum">3</span>个</div>
              </div>
            </div>
          </dv-border-box-8>

          <dv-border-box-7 class="border" v-show="!(flag == 'mapDataDL')">
            <div class="borderContent" @click="clickItem('mapDataDL')">
              <img src="../../../public/imgs/dianlu.png" alt="" />
              <div class="text">
                <div class="fixed">集成电路</div>
                <div class="fixed"><span class="fixedNum">3</span>件</div>
              </div>
            </div>
          </dv-border-box-7>
          <dv-border-box-8 class="border borderA" v-show="flag == 'mapDataDL'">
            <div class="borderContent" @click="clickItem('mapDataDL')">
              <img src="../../../public/imgs/dianlu.png" alt="" />
              <div class="text">
                <div class="fixed">集成电路</div>
                <div class="fixed"><span class="fixedNum">3</span>件</div>
              </div>
            </div>
          </dv-border-box-8>
        </div>
      </div>
    </dv-border-box-10>
  </div>
</template>
<script>
import wlmqData from "../../../public/mapData/wulumuqiMap.json";
import xjData from "../../../public/mapData/mapData.json";
import * as echarts from "echarts";
import MapEchart from "../../components/echarts/mapEchart.vue";
export default {
  components: { MapEchart },
  name: "mapData",
  data() {
    return {
      flag: "mapData",
      data_: [],
      title: "",
      titleObj: {
        mapData: "专利授权量",
        mapDataSB: "商标注册量",
        mapDataDB: "地理标志",
        mapDataDL: "集成电路",
      },
      options: {},
      mapData: "",
    };
  },
  mounted() {},
  methods: {
    clickItem(flag) {
      this.flag = flag;
    },
  },
  watch: {
    flag: {
      handler: function (val) {
        let data_ = this.$store.state.totalData.default[val];
        this.data_ = [];
        for (let i = 0; i < data_.length; i++) {
          this.data_.push({ name: data_[i].name, value: data_[i].value[2] });
        }
        // if(val=='mapData'||val=="mapDataSB"){
        // this.mapData=wlmqData
        // }else if(val=='mapDataDB'||val=="mapDataDL"){
        this.mapData = wlmqData;
        // }
        this.options = {
          tooltip: {
            trigger: "item",
            //鼠标放上去显示的数据和文字
            transitionDuration: 0,
            confine: true,
            formatter: (parms) => {
              // console.log(parms)
              return (
                parms.data.name +
                "<br/>" +
                this.titleObj[this.flag] +
                "<br/>" +
                "数量:" +
                "&nbsp;&nbsp;" +
                "<span style='font-size:0.2rem;font-weight:bold;color:rgba(255, 82, 17,1)'>" +
                parms.data.value +
                "</span>"
              );
            },
            backgroundColor: "rgba(0,0,0,0.6)",
            textStyle: {
              color: "rgba(255,255,255,1)",
            },
          },
          visualMap: {
            show: false,
            min: 1,
            max: 10000,
            text: ["High", "Low"],
            realtime: false,
            calculable: false,
            inRange: {
              color: ["lightskyblue", "yellow", "orangered"],
            },
          },
          // //在地图旁显示的数据
          series: [
            {
              name: "类型一",
              type: "map",
              mapType: "xinjiang",
              roam: false,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    textStyle: {
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "0.2rem",
                    },
                  },
                  borderColor: "#FFF", //边界线颜色
                  borderWidth: 1, //边界线大小
                  shadowColor: "rgba(255,255,255,0.1)",
                  shadowBlur: 20,
                  // areaColor: "rgb(21, 41, 77)" //地区背景颜色
                },
                emphasis: {
                  //选中或者悬浮状态
                  label: {
                    show: true,
                    textStyle: {
                      color: "rgba(0,0,0,0.5)", //选中或者悬浮字体颜色
                    },
                  },
                  areaColor: "#2b91b7", //选中或者悬浮区域颜色
                },
              },
              label: {
                normal: {
                  show: false,
                },
                emphasis: {
                  show: true,
                },
              },
              data: this.data_,
            },
            {
              name: "Top 5",
              type: "effectScatter",
              coordinateSystem: "geo",
            },
          ],
        };
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped>
#compontent {
  /* align-items: ; */
  width: 100%;
  height: 100%;
}
#btns {
  /* background-color: saddlebrown; */
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-around;
}
#echart {
  display: flex;
  align-items: center;
}
.border {
  cursor: pointer;
  width: 1.75rem /* 140px -> 1.75rem */;
  height: 1rem /* 100px -> 1.25rem */;
  display: flex;
  align-items: center;
  padding-right: 0.125rem;
}
.borderA {
  width: 1.85rem /* 148px -> 1.85rem */;
  height: 1.1rem /* 88px -> 1.1rem */;
  /* background-color: turquoise; */
}
.border img {
  width: 0.5rem /* 40px -> .5rem */;
  height: 0.5rem /* 40px -> .5rem */;
}
.borderContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.fixed {
  font-size: 0.1875rem /* 15px -> .1875rem */;
  font-weight: bold;
  text-align: center;
}
.fixedNum {
  font-size: 0.275rem /* 22px -> .275rem */;
  font-weight: bolder;
  color: #38eaff;
}
</style>