<template>
  <div id="app">
    <dv-full-screen-container>
    <Header id="header"></Header>
    <router-view id="routerView" />
    </dv-full-screen-container>
  </div>
</template>
<script>
import Header from './views/header.vue'
export default {
  components: { Header },
  data () {
    return {

    }
  }
}
</script>
<style>
#app{
  background-image: url('../public/imgs/background.png');
  height: 100%;
}
#header{
  height: 11%;
}
#routerView{
  /* background-color: aqua; */
  height: 89%;
  color: aliceblue;
}
</style>
