<template>
  <div id="compontent">
    <dv-border-box-7 backgroundColor="rgba(93, 93, 95,0.3)">
      <div class="out">
        <div class="title">
          <span class="fixed-yellow">运营</span>活动活跃机构TOP30
        </div>
        <div class="content">
          <dv-scroll-ranking-board
            :config="config"
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
    </dv-border-box-7>
  </div>
</template>
<script>
import data from "../../../public/jsData/patent";
export default {
  data() {
    return {
      needData: "",
      config: {},
    };
  },
  mounted() {
    this.needData = data;
  },
  watch: {
    needData: {
      handler: function (val) {
          let data=[]
          let me=this
          for(let i=0;i<this.needData.tableItems2.length;i++){
              data.push({name:'<span style="font-size:0.175rem;line-height:0.125rem">'+this.needData.tableItems2[i].Name+'</span>',
                         value:this.needData.tableItems2[i].Num
                         })
                if(this.needData.tableItems2[i].Ranking<=3){
                    data[i]={name:'<span style="font-size:0.2rem;line-height:0.125rem;color:#ffff00">'+this.needData.tableItems2[i].Name+'</span>',
                         value:this.needData.tableItems2[i].Num
                         }
                }
          }
        //   console.log(data)
          setTimeout(function(){
  me.config = {
          data:data,
          unit:'次',
          sort:true,
        //   carousel:'page'
        };
          },200)
      
      },
    },
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 100%;
}
.out {
  width: 95%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 10%;
  font-size: 0.225rem /* 18px -> .225rem */;
  font-weight: bold;
  line-height: 0.5rem /* 40px -> .5rem */;
}
.content {
  height: 88%;
}
</style>