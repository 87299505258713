<template>
  <div :id="id" style="width: 100%; heigth: 100%"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "mapEchart",
  props:{
    id:{
      default:'',
      typeof:String
    },
    options:{
      default:""
    },
    data:{
      default:''
    },
    isChange:{
      default:true,
      type:Boolean
    },
    mapData:{
      default:''
    },
    mapName:{
      default:"",
      type:String
    },
    isFixed:{
      default:false,
      type:Boolean
    },
    changeTime:{
      default:4000,
      type:Number
    }
  },
  data() {
    return {
      i: 0,
      intervalId: "",
      mychart: null,
    };
  },
  mounted() {},
  methods: {
    chart(id, options, mapData, mapName) {
      let me = this;
      this.mychart = echarts.init(document.getElementById(id));
      echarts.registerMap(mapName, mapData);
      this.mychart.setOption(options);
      if(this.isChange){
      this.changeItem();
       this.mychart.on("highlight", function (res) {
        if (res.dataIndex != 0) {
          me.mychart.dispatchAction({
            type: "downplay",
            seriesIndex: res.seriesIndex,
            dataIndex: res.dataIndex - 1,
          });
        } else {
          me.mychart.dispatchAction({
            type: "downplay",
            seriesIndex: res.seriesIndex,
            dataIndex: me.data.length - 1,
          });
        }
        me.mychart.dispatchAction({
          type: "showTip",
          seriesIndex: res.seriesIndex,
          dataIndex: res.dataIndex,
        });
      });
      this.mychart.on("mouseover", function () {
        clearInterval(me.intervalId);
      });
      this.mychart.on("mouseout", function () {
        clearInterval(me.intervalId);
        me.changeItem();
      });
      }
      if(this.isFixed){
        this.mychart.dispatchAction({
          type:'highlight',
          seriesIndex:0,
          name:"头屯河区"
        })
      }
    },
    changeItem() {
      let me = this;
        clearInterval(this.intervalId)
      me.intervalId = setInterval(function () {
        me.$emit('boardChange',me.data[me.i])
        // console.log(me.data[me.i])
        me.mychart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: me.i++,
        });
      }, me.changeTime);
    },
  },
  watch: {
    // options: {
    //   handler: function (val) {
    //       this.chart(this.id,val,this.mapData,this.mapName)
    //   },
    //   deep: true,
    //   immediate: true,
    // },
     i(val){
            if(val==this.data.length){
                this.i=0
            }
        },
        data:{
            handler:function(val){
                // console.log(val)
                this.$nextTick(function(){
                this.mychart=null
                this.chart(this.id,this.options,this.mapData,this.mapName)
                })
              
            },
            deep:true,
            immediate:true
        }
  },
};
</script>
<style scoped>
</style>