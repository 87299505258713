<template>
  <div id="compontent">
    <dv-border-box-6
      width="90%"
      height="90%"
      id="border"
      backgroundColor="RGBA(77, 144, 254,0.2)"
    >
      <div class="out">
        <div id="views">
          <dv-water-level-pond
            :config="config"
            style="width: 100%; height: 100%"
          />
        </div>
        <div id="icons">
          <div
            class="item"
            style="border-bottom: solid 0.025rem #31f0ed"
            :class="{ choosen: flag == 0 }"
            @click="clickItem(0)"
          >
            <img src="../../../public/imgs/susong.png" alt="" />
            <div class="item-fixed">知识产权诉讼</div>
            <div class="item-data" style="color: #31f0ed">1343件</div>
          </div>
          <div
            class="item"
            style="border-bottom: solid 0.025rem #ccb342"
            :class="{ choosen: flag == 1 }"
            @click="clickItem(1)"
          >
            <img src="../../../public/imgs/jiufen.png" alt="" />
            <div class="item-fixed">专利纠纷</div>
            <div class="item-data" style="color: #ccb342">1件</div>
          </div>
          <div
            class="item"
            style="border-bottom: solid 0.025rem #c68ae3"
            :class="{ choosen: flag == 2 }"
            @click="clickItem(2)"
          >
            <img src="../../../public/imgs/xingzheng.png" alt="" />
            <div class="item-fixed">行政处罚</div>
            <div class="item-data" style="color: #c68ae3">0件</div>
          </div>
          <div
            class="item"
            style="border-bottom: solid 0.025rem #40aedd"
            :class="{ choosen: flag == 3 }"
            @click="clickItem(3)"
          >
            <img src="../../../public/imgs/beian.png" alt="" />
            <div class="item-fixed">海关备案</div>
            <div class="item-data" style="color: #40aedd">0件</div>
          </div>
          <div
            class="item"
            style="border-bottom: solid 0.025rem #3dbdd3"
            :class="{ choosen: flag == 4 }"
            @click="clickItem(4)"
          >
            <img src="../../../public/imgs/fuwu.png" alt="" />
            <div class="item-fixed">服务机构</div>
            <div class="item-data" style="color: #3dbdd3">11家</div>
          </div>
          <div
            class="item"
            style="border-bottom: solid 0.025rem #01c078"
            :class="{ choosen: flag == 5 }"
            @click="clickItem(5)"
          >
            <img src="../../../public/imgs/lvshi.png" alt="" />
            <div class="item-fixed">专利代理律师</div>
            <div class="item-data" style="color: #01c078">19人</div>
          </div>
        </div>
      </div>
    </dv-border-box-6>
  </div>
</template>
<script>
export default {
  name: "hydrograph",
  data() {
    return {
      config: {
        data: [3430],
        formatter: "{value}" + "件",
      },
      dataObj: {
        0: {
          unit: "件",
          value: 1343,
        },
        1: {
          unit: "件",
          value: 1,
        },
        2: {
          unit: "件",
          value: 0,
        },
        3: {
          unit: "件",
          value: 0,
        },
        4: {
          unit: "家",
          value: 11,
        },
        5: {
          unit: "人",
          value: 19,
        },
      },
      flag: 0,
      intervalId:''
    };
  },
  mounted(){
    this.interval()
  },
  methods: {
    clickItem(flag) {
      let me=this
      this.flag = flag;
    clearInterval(this.intervalId)
    me.interval()
    },
    interval(){
      let me=this
      this.intervalId=setInterval(function(){
         if(me.flag>4){
            me.flag=-1
      }
         me.flag++
      },2000)
    }
  },
  watch: {
    flag: {
      handler: function (val) {
        this.config = {
          data: [this.dataObj[val].value],
          formatter: "{value}" + this.dataObj[val].unit,
        };
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 100%;
}
.out {
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: space-between;
}
.title {
  height: 12%;
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
}
.content {
  height: 83%;
  display: flex;
  justify-content: space-between;
}
#icons {
  height: 100%;
  width: 53%;
  display: flex;
  flex-direction: column;
}
.item {
  flex: 1;
  /* background-color: saddlebrown; */
  margin: 0.0625rem /* 5px -> .0625rem */ 0;
  display: flex;
  align-items: center;
  padding: 0 0.125rem /* 10px -> .125rem */;
  cursor: pointer;
}
.item img {
  width: 0.5rem /* 40px -> .5rem */;
  height: 0.5rem;
  margin-right: 0.25rem /* 20px -> .25rem */;
}
.item-child {
  display: flex;
  justify-content: left;
}
#views {
  height: 100%;
  width: 42%;
}
.item-fixed {
  font-size: 0.2rem /* 16px -> .2rem */;
}
.item-data {
  margin-left: 0.1rem /* 8px -> .1rem */;
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
}
.choosen {
  background-color: seashell;
}
</style>