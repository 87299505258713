<template>
    <div id="compontent">
          <dv-border-box-6
      width="90%"
      height="90%"
      id="border"
      backgroundColor="RGBA(77, 144, 254,0.2)"
    >
    <div class="out">
      <echart id="BarC" :options="options" :data="data" :isChange="true" />
    </div>
    </dv-border-box-6>
    </div>
</template>
<script>
import Echart from "../../components/echarts/echart.vue";
export default {
    name:'barChart',
    components:{ Echart },
    data() {
      return {
        options:"",
        data:""
      }
    },
    mounted(){
      this.$nextTick(function(){
        this.data=[132, 477, 3725]
        this.options={
     tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        },
          backgroundColor:"rgba(255,255,255,0.5)",
      textStyle:{
          color:"#FFF"
      },
       formatter:function(res){
        //  console.log(res)
          return '<span style="font-size:0.175rem">'+res[0].name+'：</span>'+'<span style="font-size:0.2rem;font-weight:bold">'+res[0].data+'</span>'
        }
    },
    grid: {
        left: '3%',
        right: '8%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
         axisLabel:{
          fontSize:'0.15rem'
        }
    },
    yAxis: {
        type: 'category',
        data: ['质押', '许可', '转让'],
         axisLabel:{
          fontSize:'0.15rem'
        }
    },
    series: [
        {
            name: '总量',
            type: 'bar',
            data: this.data
        }
    ]
};
      })
    }
}
</script>
<style scoped>
    #compontent{
        width: 100%;
        height: 95%;
    }
    .out{
         width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
    }
    .title {
  height: 12%;
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
}
.content {
  height: 83%;
  display: flex;
  justify-content: space-around;
}
</style>