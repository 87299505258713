<template>
  <div id="compontent">
    <dv-border-box-10 backgroundColor="rgba(17, 43, 116,0.3)">
      <div class="out">
        <div class="btns">
          <div
            class="btn"
            @click="clickItem(0)"
            :class="{ btnBeenChoosen: 0 == flag }"
          >
            <img :src="src1" alt="" />
            <div class="btnText">运营总量</div>
          </div>
          <div
            class="btn"
            @click="clickItem(1)"
            :class="{ btnBeenChoosen: 1 == flag }"
          >
            <img :src="src2" alt="" />
            <div class="btnText">专利许可</div>
          </div>
          <div
            class="btn"
            @click="clickItem(2)"
            :class="{ btnBeenChoosen: 2 == flag }"
          >
            <img :src="src3" alt="" />
            <div class="btnText">专利转让</div>
          </div>
          <div
            class="btn"
            @click="clickItem(3)"
            :class="{ btnBeenChoosen: 3 == flag }"
          >
            <img :src="src4" alt="" />
            <div class="btnText">专利质押</div>
          </div>
          <div class="btn">
            <dv-decoration-9
              style="
                width:80%;
                height: 100%;
                color: #f0ae18;
                font-weight: bold;
                font-size: 0.18rem;
              "
              >{{ count }}次</dv-decoration-9
            >
          </div>
          <div class="btnText"></div>
        </div>
        <div class="map">
          <map-echart
            id="mapChartZT"
            :options="options"
            :data="needData"
            :mapData="mapData"
            mapName="quanguo"
            :isChange="true"
            style="height: 100%; width: 100%"
          />
        </div>
      </div>
    </dv-border-box-10>
  </div>
</template>
<script>
import data from "../../../public/jsData/patent";
import mapData from "../../../public/mapData/quanguoMap.json";
import mapEchart from "../../components/echarts/mapEchart.vue";
export default {
  components: { mapEchart },
  data() {
    return {
      mapData: "",
      needData: "",
      options: "",
      src1: require("../../../public/imgs/yunying.png"),
      src2: require("../../../public/imgs/xuke.png"),
      src3: require("../../../public/imgs/zhuanrang.png"),
      src4: require("../../../public/imgs/zhiya.png"),
      flag: 0,
      count: 10000,
      intervalId: "",
    };
  },
  mounted() {
    this.mapData = mapData;
    this.needData = data.mapData;
    this.interval();
  },
  methods: {
    interval() {
      let me = this;
      let i = this.flag;
      this.intervalId = setInterval(function () {
        if (i > 3) {
          i = 0;
        }
        me.flag = i;
        i++;
      }, 1500);
    },
    clickItem(flag) {
      this.flag = flag;
      clearInterval(this.intervalId);
      this.interval();
    },
  },
  watch: {
    needData: {
      handler: function (val) {
        this.options = {
          tooltip: {
            trigger: "item",
            //鼠标放上去显示的数据和文字
            transitionDuration: 0,
            confine: true,
            formatter: (parms) => {
              // console.log(parms);
              if(parms){
              return (
                '<div style="text-align:center;font-size:0.175rem;font-weight:bold;border-bottom:#FFF 2px solid">' +
                parms.data.name +
                "</div>" +
                "<div>" +
                '<div style="color:#FFF;"><span style="font-size:0.175rem">总量：</span><span style="font-size:0.2rem;font-weight:bold">' +
                parms.data.value[3] +
                "次</span></div>" +
                '<div style="color:#FFF;"><span style="font-size:0.175rem">许可：</span><span style="font-size:0.2rem;font-weight:bold">' +
                parms.data.value[0] +
                "次</span></div>" +
                '<div style="color:#FFF;"><span style="font-size:0.175rem">转让：</span><span style="font-size:0.2rem;font-weight:bold">' +
                parms.data.value[1] +
                "次</span></div>" +
                '<div style="color:#FFF;"><span style="font-size:0.175rem">质押：</span><span style="font-size:0.2rem;font-weight:bold">' +
                parms.data.value[2] +
                "次</span></div>" +
                "</div>"
              );
              }
            },
            backgroundColor: "rgba(0,0,0,0.6)",
            textStyle: {
              color: "rgba(255, 82, 17,1)",
            },
          },
          visualMap: {
            show: false,
            min: 0,
            max: 500,
            text: ["High", "Low"],
            realtime: false,
            calculable: false,
            inRange: {
              color: ["lightskyblue", "yellow", "orangered"],
            },
          },
          // //在地图旁显示的数据
          series: [
            {
              name: "类型一",
              type: "map",
              mapType: "quanguo",
              roam: false,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    textStyle: {
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "0.15rem",
                    },
                  },
                  borderColor: "#FFF", //边界线颜色
                  borderWidth: 1, //边界线大小
                  shadowColor: "rgba(255,255,255,0)",
                  shadowBlur: 20,
                  // areaColor: "rgb(21, 41, 77)" //地区背景颜色
                },
                emphasis: {
                  //选中或者悬浮状态
                  label: {
                    show: true,
                    textStyle: {
                      color: "rgba(255,255,255,1)", //选中或者悬浮字体颜色
                      fontSize: "0.2rem",
                    },
                  },
                  areaColor: "#2b91b7", //选中或者悬浮区域颜色
                },
              },
              label: {
                normal: {
                  show: false,
                },
                emphasis: {
                  show: true,
                },
              },
              data: this.needData,
            },
            {
              name: "Top 5",
              type: "effectScatter",
              coordinateSystem: "geo",
            },
          ],
        };
      },
    },
    flag: {
      handler: function (val) {
        if (val == 0) {
          this.src1 = require("../../../public/imgs/yunyingChoose.png");
          this.src2 = require("../../../public/imgs/xuke.png");
          this.src3 = require("../../../public/imgs/zhuanrang.png");
          this.src4 = require("../../../public/imgs/zhiya.png");
          this.count = 8931;
        } else if (val == 1) {
          this.src1 = require("../../../public/imgs/yunying.png");
          this.src2 = require("../../../public/imgs/xukeChoose.png");
          this.src3 = require("../../../public/imgs/zhuanrang.png");
          this.src4 = require("../../../public/imgs/zhiya.png");
          this.count = 1066;
        } else if (val == 2) {
          this.src1 = require("../../../public/imgs/yunying.png");
          this.src2 = require("../../../public/imgs/xuke.png");
          this.src3 = require("../../../public/imgs/zhuanrangChoose.png");
          this.src4 = require("../../../public/imgs/zhiya.png");
          this.count = 10360;
        } else if (val == 3) {
          this.src1 = require("../../../public/imgs/yunying.png");
          this.src2 = require("../../../public/imgs/xuke.png");
          this.src3 = require("../../../public/imgs/zhuanrang.png");
          this.src4 = require("../../../public/imgs/zhiyaChoose.png");
          this.count = 318;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 100%;
}
.out {
  width: 95%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.map {
  height: 85%;
  width: 100%;
}
.btns {
  height: 15%;
  display: flex;
  justify-content: space-around;
}
.btn {
  height: 100%;
  width: 18%;
  /* background-color: blueviolet; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  font-size: 0.175rem /* 14px -> .175rem */;
  font-weight: bold;
  color: rgba(215, 215, 216, 0.7);
  cursor: pointer;
}
.btnText {
}
.btn img {
  width: 0.625rem /* 50px -> .625rem */;
  height: 0.625rem;
}
.btnBeenChoosen {
  color: rgba(243, 209, 64, 1);
  border: dashed rgba(243, 209, 64, 0.4) 0.025rem /* 2px -> .025rem */;
}
</style>