let SWData = {
    name: "生物医药",
    scrollTable: [
        ['01', '中国科学院新疆理化技术研究所', 338],
        ['02', '新疆农业大学', 237],
        ['03', '新疆医科大学', 203],
        ['04', '新疆维吾尔自治区药物研究所', 118],
        ['05', '新疆农业科学院微生物应用研究所', 111],
        ['06', '新疆大学', 106],
        ['07', '新疆医科大学第一附属医院', 90],
        ['08', '天康生物股份有限公司', 59],
        ['08', '中国科学院新疆生态与地理研究所', 59],
        ['10', '新疆维吾尔自治区中药民族药研究所', 53]
    ],
    //近五年授权量
    fImpowerNum: ['221', '224', '241', '231', '223'],
    //专利类型分布 外观 新型发明
    patentClass: [{ name: "发明授权", value: 836 }, { name: "新型", value: 14 }, { name: "发明", value: 1995 }],
    industryClassName: ['生物药品制造'],
    industryClassValue: [2845],
    industry:[{
        name: '生物药品制造',
        value:2845
    }],
    //专利运营情况
    operation: {
        total: { ZY: 18, ZR: 253, XK: 22 },
        ZY: [
            // 下面有变动 7-1 23:17
            { name: "转让", value: 253 },
            { name: "质押", value: 18 },
            { name: "许可", value: 22 }
        ],
        ZR: [
          // 下面有变动 7-1 23:17
          { name: "转让", value: 253 },
          { name: "质押", value: 18 },
          { name: "许可", value: 22 }
        ],
        XK: [
       // 下面有变动 7-1 23:17
       { name: "转让", value: 253 },
       { name: "质押", value: 18 },
       { name: "许可", value: 22 }
        ]
    },
    mapData: [
        { name: "沙依巴克区", value: [87.617733, 43.792818, 167] },
        { name: "新市区", value: [84.873946, 45.595886, 559] },
        { name: "头屯河区", value: [89.184078, 42.947613, 256] },
        { name: "水磨沟区", value: [93.51316, 42.833248, 5] },
        { name: "天山区", value: [87.304012, 44.014577, 127] },
        { name: "达坂城区", value: [82.074778, 44.903258, 3] },
        { name: "米东区", value: [86.150969, 41.768552, 4] },
        { name: "乌鲁木齐县", value: [80.265068, 41.170712, 0] }
    ]

}

let NYData = {
    name: "能源化工",
    scrollTable: [
        ['01', "中国科学院新疆理化技术研究所", 1205],
        ['02', "新疆大学", 928],
        ['03', "新疆农业大学", 331],
        ['04', "新特能源股份有限公司", 316],
        ['05', "新疆中泰化学股份有限公司", 142],
        ['06', "新疆农业科学院微生物应用研究所", 139],
        ['07', "新疆维吾尔自治区人民医院", 118],
        ['08', "新疆阜丰生物科技有限公司", 116],
        ['09', "新疆德蓝股份有限公司", 108],
        ['10', "中国科学院新疆生态与地理研究所", 106],
        ['10', "中国科学院新疆化学研究所", 106]
    ],
    //近五年授权量
    fImpowerNum: ['845', '971', '992', '1070', '1157'],
    //专利类型分布 外观 新型发明
    patentClass: [{ name: "发明授权", value: 2067 }, { name: "新型", value: 3568 }, { name: "发明", value: 5312 }],
    industryClassName: ['石油加工、炼焦和核燃料加工业', '化学原料和化学制品制造业', '橡胶和塑料制品'],
    industryClassValue: ['507', '7438', '13002'],
    industry:[
        {name:'石油加工、炼焦和核燃料加工业',value:507},
        {name:'化学原料和化学制品制造业',value:7438},
        {name:'橡胶和塑料制品',value:13002}
    ],
    //专利运营情况
    operation: {
        total: { ZY: 62, ZR: 879, XK: 87 },
        ZY: [
            { name: "质押", value: 62 },
            { name: "转让", value: 879 },
            { name: "许可", value: 87 }
        ],
        ZR: [
            { name: "质押", value: 62 },
            { name: "转让", value: 879 },
            { name: "许可", value: 87 }
        ],
        XK: [
            { name: "质押", value: 62 },
            { name: "转让", value: 879 },
            { name: "许可", value: 87 }
        ]
    },
    mapData: [
        { name: "沙依巴克区", value: [87.617733, 43.792818, 87] },
        { name: "新市区", value: [84.873946, 45.595886, 463] },
        { name: "头屯河区", value: [89.184078, 42.947613, 153] },
        { name: "水磨沟区", value: [93.51316, 42.833248, 16] },
        { name: "天山区", value: [87.304012, 44.014577, 146] },
        { name: "达坂城区", value: [82.074778, 44.903258, 0] },
        { name: "米东区", value: [86.150969, 41.768552, 8] },
        { name: "乌鲁木齐县", value: [80.265068, 41.170712,0] }
    ]

}

let JGData = {
    name: "结构材料",
    scrollTable: [
        ['01', '新疆八一钢铁股份有限公司', 960],
        ['02', '中国科学院新疆理化技术研究所', 472],
        ['03', '新疆大学', 337],
        ['04', '宝钢集团新疆八一钢铁有限公司', 274],
        ['05', '新疆众和股份有限公司', 249],
        ['06', '新疆维吾尔自治区人民医院', 110],
        ['07', '中建新疆建工（集团）有限公司', 105],
        ['08', '新疆农业大学', 92],
        ['09', '中建西部建设股份有限公司', 65],
        ['10', '新疆远麟阳光幕墙装饰工程有限公司', 56]
    ],
    //近五年授权量
    fImpowerNum: ['438', '497', '760', '600', '952'],
    //专利类型分布 发明 新型 外观
    patentClass: [{ name: "发明授权", value: 710 }, { name: "新型", value: 4016 }, { name: "发明", value: 2154 }],
    industryClassName: ['非金属矿物制品业', '黑色金属冶炼和压延加工业', '有色金属冶炼和压延加工业'],
    industryClassValue: ['5089', '706', '1085'],
    industry:[
        {name:'非金属矿物制品业',value:5089},
        {name:'黑色金属冶炼和压延加工业',value:706},
        {name:'有色金属冶炼和压延加工业',value:1085},
    ],
    //专利运营情况
    operation: {
        total: { ZY: 17, ZR: 394, XK: 54 },
        ZY: [
            { name: "质押", value: 17 },
            { name: "转让", value: 394 },
            { name: "许可", value: 54 }
        ],
        ZR: [
            { name: "质押", value: 17 },
            { name: "转让", value: 394 },
            { name: "许可", value: 54 }
        ],
        XK: [
            { name: "质押", value: 17 },
            { name: "转让", value: 394 },
            { name: "许可", value: 54 }
        ]
    },
    mapData: [
        { name: "沙依巴克区", value: [87.617733, 43.792818, 147] },
        { name: "新市区", value: [84.873946, 45.595886, 759] },
        { name: "头屯河区", value: [89.184078, 42.947613, 284] },
        { name: "水磨沟区", value: [93.51316, 42.833248, 163] },
        { name: "天山区", value: [87.304012, 44.014577, 443] },
        { name: "达坂城区", value: [82.074778, 44.903258, 87] },
        { name: "米东区", value: [86.150969, 41.768552, 66] },
        { name: "乌鲁木齐县", value: [80.265068, 41.170712, 1] }
    ]

}

let ZBData = {
    name: "装备制造",
    scrollTable: [
        ['01', '新疆八一钢铁股份有限公司', 2394],
        ['02', '新疆大学', 2181],
        ['03', '宝钢集团新疆八一钢铁有限公司', 1609],
        ['03', '中国科学院新疆理化技术研究所', 1609],
        ['05', '新疆农业大学', 1313],
        ['06', '新疆金风科技股份有限公司', 1147],
        ['07', '中国石油集团西部钻探工程有限公司', 865],
        ['08', '新疆维吾尔自治区人民医院', 848],
        ['09', '国家电网公司', 837],
        ['10', '中国科学院新疆生态与地理研究所', 716]
    ],
    //近五年授权量
    fImpowerNum: ['519', '505', '492', '525', '506'],
    //专利类型分布 发明 新型 外观
    patentClass: [{ name: "发明授权", value: 5205 }, { name: "新型", value: 30076 }, { name: "发明", value: 16208 }],
    industryClassName: ['金属制品业', '通用设备制造业', '专用设备制造业', '汽车制造业', '铁路、船舶、航空航天和其他运输设备', '电气机械和器材制造业','计算机、通信和其他电子设备制造业','仪器仪表制造业','金属制品、机械和设备修理业'],
    industryClassValue: ['7093', '13397', '25315', '1012', '852','6449','3999','25587','26538'],
    industry:[
        {name: '金属制造业',value:7093},
        {name: '通用设备制造业',value:13397},
        {name:'专用设备制造业',value:25315},
        {name: '汽车制造业',value:1012},
        {name: '铁路、船舶、航空航天和其他运输设备',value:852},
        {name: '电气机械和器材制造业',value:6449},
        {name:'计算机、通信和其他电子设备制造业',value:3999},
        {name:'仪器仪表制造业',value:25587},
        {name:'金属制品、机械和设备修理业',value:26538}
    ],
    //专利运营情况
    operation: {
        total: { ZY: 130, ZR: 3526, XK: 428 },
        ZY: [
            { name: "质押", value: 130 },
            { name: "转让", value: 3526 },
            { name: "许可", value: 428 }
        ],
        ZR: [
            { name: "质押", value: 130 },
            { name: "转让", value: 3526 },
            { name: "许可", value: 428 }
        ],
        XK: [
            { name: "质押", value: 130 },
            { name: "转让", value: 3526 },
            { name: "许可", value: 428 }
        ]
    },
    mapData: [
        { name: "沙依巴克区", value: [87.617733, 43.792818, 114] },
        { name: "新市区", value: [84.873946, 45.595886, 456] },
        { name: "头屯河区", value: [89.184078, 42.947613, 124] },
        { name: "水磨沟区", value: [93.51316, 42.833248, 86] },
        { name: "天山区", value: [87.304012, 44.014577, 87] },
        { name: "达坂城区", value: [82.074778, 44.903258, 73] },
        { name: "米东区", value: [86.150969, 41.768552, 56] },
        { name: "乌鲁木齐县", value: [80.265068, 41.170712, 23] }
    ]
}

let FZData = {
    name: "纺织服装",
    scrollTable: [
        ['01', '新疆维吾尔自治区人民医院', 96],
        ['02', '新疆大学', 69],
        ['03', '中国科学院新疆化学研究所', 45],
        ['04', '新疆医科大学第一附属医院', 34],
        ['05', '新疆农业大学', 32],
        ['06', '新疆医科大学第三附属医院', 19],
        ['07', '新疆维吾尔自治区第三机床厂', 18],
        ['08', '新疆农业科学院植物保护研究所', 16],
        ['09', '新疆医科大学第四附属医院', 15],
        ['10', '新疆砺剑泓盾防卫技术有限公司', 13]
    ],
    //近五年授权量
    fImpowerNum: ['97', '107', '138', '116', '186'],
    //专利类型分布 发明 新型 外观
    patentClass: [{ name: "发明授权", value: 106 }, { name: "新型", value: 1034 }, { name: "发明", value: 290 }],
    industryClassName: ['纺织业','纺织服装、服饰业'],
    industryClassValue: ['924','506'],
    industry:[
        {name:'纺织业',value:924},
        {name:'纺织服装、服饰业',value:924}
    ],
    //专利运营情况
    operation: {
        total: { ZY: 3, ZR: 61, XK: 7 },
        ZY: [
            { name: "质押", value: 3 },
            { name: "转让", value: 61 },
            { name: "许可", value: 7 }
        ],
        ZR: [
            { name: "质押", value: 3 },
            { name: "转让", value: 61 },
            { name: "许可", value: 7 }
        ],
        XK: [
            { name: "质押", value: 3 },
            { name: "转让", value: 61 },
            { name: "许可", value: 7 }
        ]
    },
    mapData: [
        { name: "沙依巴克区", value: [87.617733, 43.792818, 114] },
        { name: "新市区", value: [84.873946, 45.595886, 456] },
        { name: "头屯河区", value: [89.184078, 42.947613, 124] },
        { name: "水磨沟区", value: [93.51316, 42.833248, 86] },
        { name: "天山区", value: [87.304012, 44.014577, 87] },
        { name: "达坂城区", value: [82.074778, 44.903258, 73] },
        { name: "米东区", value: [86.150969, 41.768552, 56] },
        { name: "乌鲁木齐县", value: [80.265068, 41.170712, 23] }
    ]
}
//加载固定数据
var data1 = 4417
var data2 = 1968
var data3 = 3499
var data4 = 2976
var SYdata5 = 41
var SYdata6 = 1016
var SYdata7 = 31
var DLdata5 = 31
var DLdata6 = 299
var DLdata7 = 3
var MTdata5 = 9
var MTdata6 = 274
var MTdata7 = 8
var LGdata5 = 29
var LGdata6 = 224
var LGdata7 = 2

export default {

    SWData,
    NYData,
    JGData,
    ZBData,
    FZData
}