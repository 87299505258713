<template>
  <div id="compontent">
    <dv-border-box-6
      width="90%"
      height="90%"
      id="border"
      backgroundColor="RGBA(77, 144, 254,0.2)"
    >
      <div id="out">
        <div class="item title">
          <span class="fixed-yellow">授权</span>专利国民经济行业分布
        </div>
        <div class="item content">
          <div class="item-chlid">
            <div class="fixed-chlid">发明新型</div>
            <div id="leftPie">
              <echart
                id="pie"
                :options="optionsA"
                :data="dataA"
                :isChange="true"
              />
            </div>
          </div>
          <div class="item-chlid">
            <div class="fixed-chlid">外观设计</div>
            <div id="rightPie">
              <echart
                id="pieA"
                :options="optionsB"
                :data="dataB"
                :isChange="true"
              />
            </div>
          </div>
        </div>
      </div>
    </dv-border-box-6>
  </div>
</template>
<script>
import Echart from "../../components/echarts/echart.vue";
export default {
  name: "pieChartIndustry",
  components: { Echart },
  data() {
    return {
      dataA: [],
      optionsA: "",
      dataB: [],
      optionsB: "",
    };
  },
  mounted() {
    let dataA_A = this.$store.state.totalData.default.gmjjdata;
    let dataA_B = this.$store.state.totalData.default.gmjjdata1;
    for (let i = 0; i < dataA_A.length; i++) {
      this.dataA.push({ value: dataA_B[i], name: dataA_A[i] });
    }
    this.optionsA = {
      tooltip: {
        trigger: "item",
        backgroundColor: "rgba(0,0,0,0.2)",
        textStyle: {
          color: "#FFF",
        },
        position: function (point, params, dom, rect, size) {
          return {
            // left:(size.viewSize[0]-size.contentSize[0])/2,
            // top:(size.viewSize[1]-size.contentSize[1])/2
            left: point[0] + 10,
            top: point[1] + 10,
          };
        },
        confine: true,
        formatter: function (res) {
          return (
            '<span style="font-size:0.175rem">' +
            res.data.name +
            "<br/>" +
            "数量：</span>" +
            '<span style="font-size:0.2rem;font-weight:bold">' +
            res.data.value +
            "</span>"
          );
        },
      },
      series: [
        {
          name: "访问来源",
          type: "pie",
          radius: ["100%", "50%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 5,
            borderColor: "#fff",
            borderWidth: 0,
          },
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: this.dataA,
        },
      ],
    };

    let dataB_A = this.$store.state.totalData.default.gmjjdataWG;
    let dataB_B = this.$store.state.totalData.default.gmjjdataWGValue;
    for (let i = 0; i < dataB_A.length; i++) {
      this.dataB.push({ value: dataB_B[i], name: dataB_A[i] });
    }
    this.optionsB = {
      tooltip: {
        trigger: "item",
        backgroundColor: "rgba(0,0,0,0.2)",
        textStyle: {
          color: "#FFF",
        },
        position: function (point, params, dom, rect, size) {
          return {
            // left:(size.viewSize[0]-size.contentSize[0])/2,
            // top:(size.viewSize[1]-size.contentSize[1])/2
            left: point[0] + 10,
            top: point[1] + 10,
          };
        },
        confine: true,
        formatter: function (res) {
          return (
            '<span style="font-size:0.175rem">' +
            res.data.name +
            "<br/>" +
            "数量：</span>" +
            '<span style="font-size:0.2rem;font-weight:bold">' +
            res.data.value +
            "</span>"
          );
        },
      },
      series: [
        {
          name: "数量",
          type: "pie",
          radius: ["100%", "50%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 5,
            borderColor: "#fff",
            borderWidth: 0,
          },
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: this.dataB,
        },
      ],
    };
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 100%;
}
#out {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 12%;
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
}
.content {
  height: 83%;
  display: flex;
  justify-content: space-around;
}
.item-chlid {
  width: 45%;
  height: 100%;
}
.fixed-chlid {
  font-size: 0.2rem;
  height: 15%;
  line-height: 0.3125rem /* 25px -> .3125rem */;
}
#leftPie,
#rightPie {
  height: 82%;
  width: 100%;
}
</style>