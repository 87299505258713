<template>
  <div class="about">
    <div class="left">
      <table-view :data="compData" />
    </div>
    <div class="middle">
      <map-view />
    </div>
    <div class="right">
      <pie-groups />
    </div>
  </div>
</template>
<script>
import data from '../../public/jsData/borad'
import MapView from './board/mapView.vue'
import PieGroups from './board/pieGroups.vue'
import tableView from './board/tableView.vue'
export default {
  components: { tableView, MapView, PieGroups },
  data() {
    return {
      compData:''
    }
  },
  mounted(){
    this.compData=data
  }

}
</script>
<style scoped>
.about{
  display: flex;
  justify-content: space-around;
}
.left{
  width: 27%;
  
}
.middle{
  width: 45%;
}
.right{
  width: 27%;
}
</style>
