<template>
  <div id="compontent">
    <dv-border-box-7 width="85%" backgroundColor="RGBA(77, 144, 254,0.2)">
      <div class="out">
        <div class="title">
          <span class="fixed-yellow">{{ classData.name }}</span
          >产业创新情况
        </div>
        <div class="content">
          <div class="left">
            <echart
              id="BarAA"
              :options="optionsLeft"
              :data="classData.patentClass"
              :isChange="true"
            />
          </div>
          <div>
            <dv-decoration-2
              :reverse="true"
              dur="2"
              style="width: 0.0625rem; height: 50%; transform: rotateX(180deg)"
            />
            <dv-decoration-2
              :reverse="true"
              dur="2"
              style="width: 0.0625rem; height: 50%"
            />
          </div>
          <div class="middle">
            <echart
              id="LineA"
              :data="classData.fImpowerNum"
              :options="optionsMiddle"
              :isChange="true"
            />
          </div>
          <div>
            <dv-decoration-2
              :reverse="true"
              dur="2"
              style="width: 0.0625rem; height: 50%; transform: rotateX(180deg)"
            />
            <dv-decoration-2
              :reverse="true"
              dur="2"
              style="width: 0.0625rem; height: 50%"
            />
          </div>
          <div class="right">
            <echart
              id="BarCC"
              :data="classData.industryClassValue"
              :options="optionsRight"
              :isChange="true"
            />
          </div>
        </div>
      </div>
    </dv-border-box-7>
  </div>
</template>
<script>
import echart from "../../components/echarts/echart.vue";
export default {
  components: { echart },
  props: ["data"],
  data() {
    return {
      classData: "",
      optionsLeft: "",
      optionsMiddle: "",
      optionsRight: "",
    };
  },
  mounted() {},
  watch: {
    data: {
      handler: function (val) {
        this.classData = val;
        this.optionsLeft = {
          tooltip: {
            trigger: "item",
            backgroundColor: "rgba(255,255,255,0.5)",
            textStyle: {
              color: "#FFF",
            },
            confine: true,
            formatter: function (res) {
              return (
                '<span style="font-size:0.175rem">' +
                res.data.name +
                "<br/>" +
                "数量：</span>" +
                '<span style="font-size:0.2rem;font-weight:bold">' +
                res.data.value +
                "</span>"
              );
            },
          },
          series: [
            {
              name: "数量",
              type: "pie",
              radius: ["50%", "80%"],
              // center: ['50%', '50%'],
              roseType: "area",
              itemStyle: {
                borderRadius: 8,
              },
              data: this.classData.patentClass,
              labelLine: {
                show: true,
                lineStyle: {
                  width: 2,
                },
              },
              label: {
                //文本样式
                normal: {
                  textStyle: {
                    fontSize: "0.2rem", // 改变标示文字的大小
                    color: "#f1f1fe",
                  },
                },
              },
            },
          ],
        };
        this.optionsMiddle = {
          tooltip: {
            trigger: "item",
            backgroundColor: "rgba(255,255,255,0.5)",
            textStyle: {
              color: "#FFF",
            },
            formatter: function (res) {
              return (
                '<div style="font-size:0.2rem">专利授权量</div>' +
                '<span style="font-size:0.175rem">' +
                res.name +
                "：</span>" +
                '<span style="font-size:0.2rem;font-weight:bold">' +
                res.data +
                "</span>"
              );
            },
          },
          xAxis: {
            type: "category",
            data: ["2016年", "2017年", "2018年", "2019年", "2020年"],
            axisLabel: {
              fontSize: "0.15rem",
            },
          },
          yAxis: {
            nameTextStyle: {
              color: "#5c99c5",
              fontSize: "0.15rem",
            },
            name: "数量(件)",
            type: "value",
            axisLabel: {
              fontSize: "0.15rem",
            },
          },
          series: [
            {
              data: this.classData.fImpowerNum,
              type: "line",
              lineStyle: {
                color: "rgba(145, 204, 117,1)",
              },
              smooth: true,
              areaStyle: {
                normal: {
                  color: {
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: "0.125rem",
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(160, 49, 108,0.5)", // 0% 处的颜色
                      },
                      {
                        offset: 0.7,
                        color: "rgba(220,56,129,0)", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false, // 缺省为 false
                  },
                },
              },
            },
          ],
          grid: {
            top: 40,
            x: 70,
            x2: 45,
            y2: "30px",
          },
        };
        this.optionsRight = {
          tooltip: {
            trigger: "item",
            backgroundColor: "rgba(0,0,0,0.5)",
            textStyle: {
              color: "#FFF",
            },
            confine: true,
            formatter: function (res) {
              return (
                '<span style="font-size:0.175rem">' +
                res.data.name +
                "<br/>" +
                "数量：</span>" +
                '<span style="font-size:0.2rem;font-weight:bold">' +
                res.data.value +
                "</span>"
              );
            },
          },
          series: [
            {
              name: "访问来源",
              type: "pie",
              data: this.classData.industry,
              emphasis: {
                itemStyle: {
                  shadowBlur: 20,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
          ],
        };
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 95%;
}
.out {
  height: 95%;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 12%;
  font-size: 0.3rem /* 24px -> .3rem */;
  font-weight: bold;
}
.content {
  height: 88%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.left {
  width: 30%;
  height: 100%;
}
.middle {
  width: 35%;
  height: 100%;
}
.right {
  width: 30%;
  height: 100%;
}
</style>