<template>
  <div id="compontent">
    <dv-border-box-6
      width="90%"
      height="90%"
      id="border"
      backgroundColor="RGBA(77, 144, 254,0.2)"
    >
      <div class="out">
        <div class="item title">
          <span class="fixed-yellow">专利</span>申请类型分布
        </div>
        <div class="item content">
          <echart :options="options" :data="data" id="pieB" :isChange="true" />
        </div>
      </div>
    </dv-border-box-6>
  </div>
</template>
<script>
import Echart from "../../components/echarts/echart.vue";
export default {
  components: { Echart },
  name: "pieChartClass",
  data() {
    return {
      data: [
        { value: 21590, name: "发明" },
        { value: 7852, name: "外观" },
        { value: 30135, name: "新型" },
      ],
      options: "",
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.options = {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(255,255,255,0.5)",
          textStyle: {
            color: "#FFF",
          },
          confine:true,
           formatter:function(res){
          return '<span style="font-size:0.175rem">'+res.data.name+'<br/>'+'数量：</span>'+'<span style="font-size:0.2rem;font-weight:bold">'+res.data.value+'</span>'
        }
        },
        series: [
          {
            name: "数量",
            type: "pie",
            radius: ["50%", "80%"],
            // center: ['50%', '50%'],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            data: this.data,
            labelLine: {
              show: true,
              lineStyle: {
                width: 2,
              },
            },
            label: {
              //文本样式
              normal: {
                textStyle: {
                  fontSize: "0.2rem", // 改变标示文字的大小
                  color: "#f1f1fe",
                },
              },
            },
          },
        ],
      };
    });
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 100%;
}
.out {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 12%;
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
}
.content {
  height: 83%;
  display: flex;
  justify-content: space-around;
}
</style>