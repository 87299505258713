<template>
  <div id="compontent">
    <dv-border-box-1 width="90%" height="90%" backgroundColor="RGBA(77, 144, 254,0.2)">
      <div style="
          width: 90%;
          height: 95%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        ">
        <div id="top">
          <div class="title">
            <span class="fixed-yellow">授权</span>专利领域分布情况
          </div>
          <div class="table child">
            <dv-scroll-board :config="configA" style="width: 100%; height: 100%" />
          </div>
        </div>
        <div id="bottom">
          <div class="title">
            <span class="fixed-yellow">专利</span>授权量机构排名
          </div>
          <div class="table child">
            <dv-scroll-board :config="configB" style="width: 100%; height: 100%" />
          </div>
        </div>
      </div>
    </dv-border-box-1>
  </div>
</template>
<script>
  export default {
    props: ["data"],
    data() {
      return {
        configA: {},
        configB: {}
      };
    },
    mounted() {},
    watch: {
      data: function(val) {
        let data1 = val.tableItems1;
        let data2 = val.tableItems2;
        for (let i = 0; i < data1.length; i++) {
          for (let j = 0; j < data1[i].length; j++) {
            if (i < 3) {
              let a = "<span style='font-size:0.25rem;color:#fac858'>" + data1[i][j] + "</span>";
              data1[i][j] = a;
            } else {
              let a = "<span style='font-size:0.2rem'>" + data1[i][j] + "</span>";
              data1[i][j] = a;
            }

          }
        }
        for (let i = 0; i < data2.length; i++) {
          for (let j = 0; j < data2[i].length; j++) {
            if (i < 3) {
              let a = "<span style='font-size:0.25rem;color:#fac858'>" + data2[i][j] + "</span>";
              data2[i][j] = a;
            } else {
              let a = "<span style='font-size:0.2rem'>" + data2[i][j] + "</span>";
              data2[i][j] = a;
            }

          }
        }
        this.configA = {
          data: data1,
          header: [
            "<span style='font-size:0.2rem'>排名</span>",
            "<span style='font-size:0.2rem'>IPC号</span>",
            "<span style='font-size:0.2rem'>专利授权量</span>",
          ],
          waitTime: 2500,
          headerHeight: 40,
          oddRowBGC: "RGBA(77, 144, 254,0.1)",
          evenRowBGC: "RGBA(10, 39, 50,0.2)",
          headerBGC: "RGBA(77, 144, 254,0.4)",
          align: ["center", "center", "center"],
          columnWidth:[100,300,120]
        };

        this.configB = {
          data: data2,
          header: [
            "<span style='font-size:0.2rem'>排名</span>",
            "<span style='font-size:0.2rem'>机构名称</span>",
            "<span style='font-size:0.2rem'>专利授权量</span>",
          ],
          waitTime: 2500,
          headerHeight: 40,
          oddRowBGC: "RGBA(77, 144, 254,0.1)",
          evenRowBGC: "RGBA(10, 39, 50,0.2)",
          headerBGC: "RGBA(77, 144, 254,0.4)",
          align: ["center", "center", "center"],
          columnWidth:[100,300,120]
        };
      },
    },
  };
</script>
<style scoped>
  #compontent {
    width: 100%;
    height: 98%;
  }

  #top {
    height: 48%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  #bottom {
    height: 48%;
    width: 100%;
    /* background-color: brown; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .title {
    height: 10%;
    font-size: 0.25rem
      /* 20px -> .25rem */
    ;
    font-weight: bold;
    line-height: 0.625rem
      /* 50px -> .625rem */
    ;
  }

  .child {
    height: 85%;
    width: 100%;
  }
</style>
