<template>
  <div class="about">
    <div class="child top">
      <div class="item left">
        <scroll-table :data="compontentData" />
      </div>
      <div class="item middle" style="height: 100%;">
        <map-data @clickItem="clickItem($event)" :data="compontentData" />
      </div>
      <div class="item right">
        <operation :data="compontentData" />
      </div>
    </div>
      <dv-decoration-5 style="width: 100%; height: 2%" />
    <div class="child bottom">
      <innovate :data="compontentData"/>
    </div>
  </div>
</template>
<script>
//引入数据
import data from "../../public/jsData/industry";
//引入组件
import Innovate from './industry/innovate'
import MapData from "./industry/mapData.vue";
import Operation from './industry/operation.vue';
import ScrollTable from './industry/scrollTable.vue';
export default {
  components: { MapData, ScrollTable, Operation, Innovate },
  name: "industry",
  data() {
    return {
      compontentData: "",
      flag: "",
      allData:""
    };
  },
  mounted() {
    // console.log(1)
    //处理数据
    this.allData=this.$store.state.industryData.default
    for(let key in this.allData){
      let dataITem=this.allData[key]
      for(let i=0;i<dataITem.mapData.length;i++){
          if(dataITem.mapData[i].value.length==3){
            this.allData[key].mapData[i].value.splice(0,2)
          }
      }
    }
  },
  methods: {
    clickItem(e) {
      this.flag = e;
      this.compontentData = this.allData[this.flag + "Data"];
      // console.log(this.compontentData);
    }
  }
};
</script>
<style scoped>
.about {
  color: aliceblue;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* background-color: blanchedalmond; */
}
.top{
  flex: 65%;
  display: flex;
  justify-content: space-around;
}
.bottom{
  flex: 33%;
}
.left{
  width: 29%;
}
.middle{
  width: 40%;
}
.right{
  width: 29%;
  height: 100%;
}
</style>
