<template>
  <div :id="id" style="width: 100%; height: 100%"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "echart",
  props: ["id", "options", "data", "isChange"],
  data() {
    return {
      i: 0,
      intervalId: "",
      mychart: "",
    };
  },
  mounted() {},
  methods: {
    settingEchart(id, options) {
      let me = this;
      let dom_ = document.getElementById(id);
      this.mychart = echarts.init(dom_);
      this.mychart.setOption(options);
        //    this.interval()
      if (this.isChange) {
        this.changeItem();
      }
        //监听鼠标悬停
        this.mychart.on("mouseover", function () {
          clearInterval(me.intervalId);
        });
        this.mychart.on("mouseout", function () {
          clearInterval(me.intervalId);
          me.changeItem();
        });
        this.mychart.on("highlight", function (a) {
          me.mychart.dispatchAction({
            type: "showTip",
            seriesIndex: a.seriesIndex,
            dataIndex: a.dataIndex,
            position: function (point, params, dom, rect, size) {
              return {
                left: point[0] - 20,
                top: point[1] - 20,
              };
            },
          });
          if (a.dataIndex != 0) {
            me.mychart.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: a.dataIndex - 1,
            });
          } else {
            me.mychart.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: me.data.length - 1,
            });
          }
        });
    },
    //饼图自动切换
    changeItem() {
      let me = this;
    
      clearInterval(this.intervalId);
      me.intervalId = setInterval(function () {
      me.interval()
      }, 4000);
    },
    interval(){
        let me=this
          me.mychart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: me.i++,
        });
    }
  },
  watch: {
    options: {
      handler: function (val) {
        this.settingEchart(this.id, val);
      },
      deep: true,
    },
    i(val) {
      if (val == this.data.length) {
        this.i = 0;
      }
    },
  },
};
</script>
<style scoped>
</style>