<template>
  <div id="compontent">
    <dv-border-box-10
      width="90%"
      height="90%"
      backgroundColor="RGBA(77, 144, 254,0.2)"
    >
      <div
        style="
          height: 95%;
          width: 90%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        "
      >
        <div class="top">
          <div class="title">
            <dv-decoration-7 style="width: 100%; height: 0.375rem"
              >乌鲁木齐市{{position}}看板</dv-decoration-7
            >
          </div>
          <div class="content">
            <map-echart
              :mapData="mapData"
              mapName="xinjiang"
              id="mapChartDd"
              :options="options"
              :isChange="true"
              style="width: 100%; height: 80%"
              :isFixed="false"
              :data="data"
              @boardChange = 'boardChange($event)'
              :changeTime="10000"
            />
            <div class="infor">
                  {{text}}
            </div>
          </div>
        </div>
        <div class="bottom">
          <div
            class="item"
            @click="clickItem({ name: '家', value: 86803, flag: 0 })"
            :class="{ beenChoosen: flag == 0 }"
          >
            <img :src="src1" alt="" />
            <div class="item-fixed">机构总量</div>
          </div>
          <div
            class="item"
            @click="clickItem({ name: '件', value: 2939, flag: 1 })"
            :class="{ beenChoosen: flag == 1 }"
          >
            <img :src="src2" alt="" />
            <div class="item-fixed">专利总量</div>
          </div>
          <div
            class="item"
            @click="clickItem({ name: '件', value: 7720, flag: 2 })"
            :class="{ beenChoosen: flag == 2 }"
          >
            <img :src="src3" alt="" />
            <div class="item-fixed">商标总量</div>
          </div>
          <div class="num">
            <dv-digital-flop
              :config="config"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
      </div>
    </dv-border-box-10>
  </div>
</template>
<script>
import mapData from "../../../public/mapData/wulumuqiMap.json";
import mapEchart from "../../components/echarts/mapEchart.vue";
export default {
  components: { mapEchart },
  data() {
    return {
      mapData: "",
      options: "",
      config: {
        number: [86803],
        style: {
          fontSize: 50,
        },
        content: "{nt}家",
      },
      flag: 0,
      src1: require("../../../public/imgs/jigouNumChoose.png"),
      src2: require("../../../public/imgs/zhuanliNumChoose.png"),
      src3: require("../../../public/imgs/shangbiaoNumChoose.png"),
      intervalId: "",
      data: ['ts','sb','xs','sm','tt','dbc','md','wlmq'],
      textObj:{
        ts:'天山区是乌鲁木齐市的发源地，旧称“迪化”，曾被誉为“耕凿弦涌之乡，歌舞游冶之地”，位于乌鲁木齐市东南部。辖区总面积近200平方公里，另有草场34.82万亩（约232平方公里），建城区面积32.79平方公里。驻地单位1060个。辖区驻有自治区、乌鲁木齐市党、政、军和新疆生产建设兵团的首脑机关，是乌鲁木齐市的政治、经济、文化、金融中心。区经济持续、稳定、健康发展。2002年实现国内生产总值63.8亿元，其中第三产业增加值50.5亿元，占国内生产总值的79.1%；实现城区级财政收入2.6亿元，较上年增长15%，GDP和地方财政收入继续列我市各区县之首。',
        sb:'沙依巴克区是乌鲁木齐市的中心城区之一，总面积为427多平方公里，建成区面积25平方公里，全区总人口52.8万，沙依巴克区维吾尔语意为“戈壁滩上的花园”，是乌鲁木齐市唯一用维吾尔语命名的城区。沙依巴克区交通便利，通讯发达，得天独厚的地缘、地理环境使沙区具备了优越的经济发展条件，欧亚大陆桥的兰新铁路、北疆铁路横贯城区，与通往南北疆各地的公路及市区道路纵横交错，南北交通枢纽火车站、长途汽车客运站、民航售票处和全疆大部分地州政府驻乌办事处聚集区内，特别是商贸城国际二类口岸、国际客运二类口岸、邮电国际二类口岸的开通，使沙依巴克区成为国内外人们观察审视乌鲁木齐建设与发展的窗口。',
        xs:'新市区位于乌鲁木齐市西北部，是自治区首府中心城区之一，城区总面积142平方公里，人口约44万，这里科研院所云集，大中专院校众多，辖区共有大中专院校34所，中小学校63所。新市区区委、政府紧紧抓住国家西部大开发和乌鲁木齐建设现代化国际商贸城的历史机遇，按照东联西、南北合作，共同发展的思路，积极探索经济与社会跨越式发展的途径，并为之而奋起二次创业。目前全区民族团结、社会稳定、经济繁荣、科技进步、环境优美、政策优惠，是投资和发展的良好区域。',
        sm:'水磨沟区是乌鲁木齐市中心城区之一。区域面积121.7平方公里，人口25.5万，水区历届区委、区政府坚持以经济建设为中心，坚持党的改革开放政策，以发展区域经济，造福水区人民为目标，团结带领全区各级党政组织、驻区单位和全区各族人民开拓创新、求真务实，取得了改革开放和经济建设的巨大成就，已成为乌鲁木齐重要的中心区、工业区、商贸区和旅游区。南湖安居工程、西北最大的华凌建材综合市场的崛起，水磨沟、红山风景旅游区的开发建设，使水磨沟的土地资源、旅游资源迅速增值并进一步发展壮大。',
        tt:`              乌鲁木齐经济技术开发区1994年8月经国务院批准设立，
              是全疆第—个国家级经开区;2011年1月，与市辖行政区头屯河区合并，
              实行"区政合—”，成为集国家级开发区、行政区、国际陆港区、
              综合保税区及若干特色功能园区于—体的多功能复合型经济区。
              是丝绸之路经济带核心区建设的重要承载区。全区规划管理面积490平方公里,
              总人口35万人，辖区注册企业2.1万家，其中四上企业623家。
              工业总产值亿元以上企业43家，外商投资企业47家，世界500强22家，中国500强40家。`,
        dbc:'达坂城区位于乌鲁木齐市东南，其行政中心达坂城镇距市区80余公里，全区总人口为45000余人(有常驻户口的41770人)，非农业人口19329人，占全区总人口的43％。达坂城区由于独特的地理构造，隐藏着丰富的矿产资源，其中已探明煤炭储量5.63亿吨，石灰石储量2.62亿吨，芒硝储量10907吨，石盐储量1288.8万吨，石膏储量247万吨。区内建有全国最大的风力发电厂，已安装172台风力发电机，装机总量居全国首位，年发电量1.82亿千瓦时。',
        md:'米东区隶属于新疆自治区乌鲁木齐市，2007年8月经国务院批准，由昌吉回族自治州原米泉市和原乌鲁木齐市东山区合并成立。全区总面积3407.42平方千米，区内常住人口333676人（2010年）。米东区2018年实现GDP353亿元，同比增长3.1%。米东区2018年地方财政收入完成65.13亿元，同比增长15.3%。总量在各区县中排第四；增速在各区县中排第五。米东区2018年固定资产投资额完成200.6亿元，同比增长17.9%。米东区固定资产投资额占全市的11.3%，总量在各区县中排第四；增速比全市增速高7.9个百分点，增速在各区县中居第一位。',
        wlmq:'乌鲁木齐县，地处天山北麓，准噶尔盆地南缘，2018年全县总人口52763人。2017年，乌鲁木齐县完成地区生产总值23.11亿元，增速5.2%；其中第一产业实现增加值7.61亿元，增速2.4%；第二产业实现增加值4.91亿元，增速9.6%；第三产业实现增加值10.58亿元，增速5.3%2018年全县完成地区生产总值25.04亿元，同比增长7.8%；其中第一产业实现增加值7.6亿元；第二产业实现增加值5.5亿元；第三产业实现增加值11.94亿元；一、二、三产比例为30:21:49。'
      },
      text:`  天山区是乌鲁木齐市的发源地，旧称“迪化”，曾被誉为“耕凿弦涌之乡，歌舞游冶之地”，位于乌鲁木齐市东南部。辖区总面积近200平方公里，另有草场34.82万亩（约232平方公里），建城区面积32.79平方公里。驻地单位1060个。辖区驻有自治区、乌鲁木齐市党、政、军和新疆生产建设兵团的首脑机关，是乌鲁木齐市的政治、经济、文化、金融中心。区经济持续、稳定、健康发展。2002年实现国内生产总值63.8亿元，其中第三产业增加值50.5亿元，占国内生产总值的79.1%；实现城区级财政收入2.6亿元，较上年增长15%，GDP和地方财政收入继续列我市各区县之首。`,
      positionObj:{
        ts:'天山区',
        sb:'沙依巴克区',
        xs:'新市区',
        sm:'水磨沟区',
        tt:'头屯河区',
        dbc:'达坂城区',
        md:'米东区',
        wlmq:'乌鲁木齐县'
      },
      position:'天山区'
    };
  },
  mounted() {
    // console.log(mapData)
    this.mapData = mapData;
    this.interval();
  },
  methods: {
    clickItem(e) {
      this.flag = e.flag;
      clearInterval(this.intervalId);
      this.interval();
    },
    interval() {
      let me = this;
      let i = this.flag;
      this.intervalId = setInterval(function () {
        if (i > 2) {
          i = 0;
        }
        me.flag = i;
        i++;
      }, 3500);
    },
    boardChange(e){
     this.text = this.textObj[e]
     this.position = this.positionObj[e]
    }
  },
  watch: {
    mapData: {
      handler: function (val) {
        this.options = {
          tooltip: {
            formatter: function(){

            }
          },
          // //在地图旁显示的数据
          series: [
            {
              name: "类型一",
              type: "map",
              mapType: "xinjiang",
              roam: false,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    textStyle: {
                      color: "#f4e925",
                      fontSize: "0.15rem",
                    },
                  },
                  borderColor: "#3fdaff", //边界线颜色
                  borderWidth: 1, //边界线大小
                  shadowColor: "rgba(255,255,255,0)",
                  shadowBlur: 20,
                  areaColor: "rgb(21, 41, 77)", //地区背景颜色
                },
                emphasis: {
                  //选中或者悬浮状态
                  label: {
                    show: false,
                    textStyle: {
                      color: "#fff", //选中或者悬浮字体颜色
                      fontSize: '0.2rem'
                    },
                  },
                  areaColor: "rgba(212, 58, 0,1)", //选中或者悬浮区域颜色
                },
              },
              label: {
                normal: {
                  show: false,
                },
                emphasis: {
                  show: true,
                },
              },
            },
          ],
        };
      },
    },
    flag: {
      handler: function (val) {
        if (val == 0) {
          this.src1 = require("../../../public/imgs/jigouNum.png");
          this.src2 = require("../../../public/imgs/zhuanliNumChoose.png");
          this.src3 = require("../../../public/imgs/shangbiaoNumChoose.png");
          this.config = {
            number: [86803],
            style: {
              fontSize: 40,
            },
            content: "{nt}家",
          };
        } else if (val == 1) {
          this.src1 = require("../../../public/imgs/jigouNumChoose.png");
          this.src2 = require("../../../public/imgs/zhuanliNum.png");
          this.src3 = require("../../../public/imgs/shangbiaoNumChoose.png");
          this.config = {
            number: [2939],
            style: {
              fontSize: 40,
            },
            content: "{nt}件",
          };
        } else if (val == 2) {
          this.src1 = require("../../../public/imgs/jigouNumChoose.png");
          this.src2 = require("../../../public/imgs/zhuanliNumChoose.png");
          this.src3 = require("../../../public/imgs/shangbiaoNum.png");
          this.config = {
            number: [7720],
            style: {
              fontSize: 40,
            },
            content: "{nt}件",
          };
        }
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 98%;
  /* background-color: #00FFFF; */
}

.bottom {
  width: 100%;
  height: 15%;
  /* background-color: #05304d; */
}
.top {
  width: 100%;
  height: 82%;
  /* background-color: #00FFFF; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 8%;
  width: 100%;
  font-size: 0.3125rem;
  font-weight: bold;
  text-align: center;
  line-height: 0.625rem;
}
.content {
  width: 100%;
  height: 90%;
  position: relative;
}
.infor {
  position: absolute;
  /* top:65%; */
  bottom: 0%;
  left: 0;
  font-size: 0.225rem /* 18px -> .225rem */;
  line-height: 0.375rem /* 30px -> .375rem */;
  padding: 0.25rem /* 20px -> .25rem */;
  background-color: rgba(21, 37, 69, 0.8);
  align-items: center;
  letter-spacing: 0.025rem /* 2px -> .025rem */;
}
.bottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.bottom img {
  width: 0.625rem /* 50px -> .625rem */;
  height: 0.625rem /* 50px -> .625rem */;
}
.item {
  padding: 0.1rem /* 8px -> .1rem */;
  width: 18%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: rgba(65, 105, 225,0.4) 4px solid; */
  border: rgba(205, 205, 205, 0.2) 4px solid;
  color: rgba(205, 205, 205, 0.2);
}
.num {
  width: 30%;
  height: 100%;
}
.item-fixed {
  margin-top: 0.125rem /* 10px -> .125rem */;
  font-size: 0.2rem /* 16px -> .2rem */;
  font-weight: bold;
}
.beenChoosen {
  color: white;
  border: rgba(65, 105, 225, 0.4) 4px solid;
}
</style>
