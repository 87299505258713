var tableItems1 = [
    //完成
    ['01','A01G1/00',339],
    ['02','F24F5/00',305],
    ['03','C12N1/20',265],
    ['04','A61P35/00',257],
    ['05','G06Q50/06',235],
    ['06','A01G17/00',224],
    ['07','G06K9/00',217],
    ['08','C12Q1/68',214],
    ['09','A23L1/29',200],
    ['10','H05K7/20',197]
    ]
    
    
    var tableItems2 = [
        //完成
        ['01','新疆八一钢铁股份有限公司',2349],
        ['02','新疆大学',2257],
        ['03','中国科学院新疆理化技术研究所',1614],
        ['04','宝钢集团新疆八一钢铁有限公司',1608],
        ['05','美克国际家居用品股份有限公司',1506],
        ['06','新疆农业大学',1350],
        ['07','新疆金风科技股份有限公司',1130],
        ['08','国家电网公司',880],
        ['09','中国石油集团西部钻探工程有限公司',830],
        ['10','新疆维吾尔自治区人民医院',818],
        ['11','中国科学院新疆生态与地理研究所',767],
        ['12','新疆医科大学第一附属医院',621],
        ['13','国家电网有限公司',569],
        ['14','新特能源股份有限公司',470],
        ['15','新疆工程学院',459],
        ['16','新疆农业科学院农业机械化研究所',404],
        ['17','新疆众和股份有限公司',370],
        ['18','国网新疆电力有限公司电力科学研究院',364],
        ['19','新疆医科大学',354],
        ['20','特变电工新疆新能源股份有限公司',334]
    ]
    
    
    //专利类型饼图
    let zllxdata=[
        {name:'发明',value:21590},
        {name:"新型",value:30135},
        {name:"外观",value:7852}
    ]
    
    //中2_2使用数据
    let zlyydata=[
        {name:'许可',value:477},
        {name:'转让',value:3725},
        {name:'质押',value:132}
    ]
    
    
     //中2_1国民经济分类饼图
    //  var gmjjdata =	['A 农、林、牧、渔业','B 采矿业','C 制造业','D 电力、热力、燃气及水生产和供应业','E 建筑业','I 信息传输、软件和信息技术服务业','O 居民服务、修理和其他服务业'];
    //  var gmjjdata1 =	['79','18','2543','137','212','25','94'];
    //  var gmjjdataWG =	['17 纺织业','18 纺织服装、服饰业','20 木材加工和木、竹、藤、棕、草制品业','24 文教、工美、体育和娱乐用品制造业',
    //  '26 化学原料和化学制品制造业','29 橡胶和塑料制品业','30 非金属矿物制品业','31 黑色金属冶炼和压延加工业',
    //  '33 金属制品业','34 通用设备制造业','35 专用设备制造业','38 电气机械和器材制造业','39 计算机、通信和其他电子设备制造业','40 仪器仪表制造业','41 其他制造业'];
    //  var gmjjdataWGValue =	['10','6','54','12','4','114','39','3','88','23','25','24','25','2','4'];
    let gmjjdata=[
        {name:"农、林、牧、渔业",value:1205},
        {name:'采矿业',value:812},
        {name:'制造业',value:32530},
        {name:'居民服务、修理和其他服务业',value:2600}
    ]
    let gmjjdataWG=[
        {name:'农副食品加工',value:946},
        {name:'酒、饮料和精制茶制造业',value:854},
        {name:'烟草制品业',value:46}
    ]
    
    //计时器
    var table1Interval = '';
    var table2Interval = '';
     //加载固定数据
    var data1 = 	"1" //企业数
    var data2 = 	86803
    var data3 = 	2939
    var data4 = 	7720

    export default{
        tableItems1,
        tableItems2,
        zllxdata,
        zlyydata,
        gmjjdata,
        gmjjdataWG
    }