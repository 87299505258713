var tableItems1_1 = [
    { "Ranking": "01", "Icp": "E21B(土层或岩石的钻进)", "Name": "中国石油集团西部钻探工程有限公司", "Num": "1293" },
    { "Ranking": "02", "Icp": "E21B(土层或岩石的钻进)", "Name": "克拉玛依胜利高原机械有限公司", "Num": "82" },
    { "Ranking": "03", "Icp": "E21B(土层或岩石的钻进)", "Name": "新疆华油油气工程有限公司", "Num": "78" },
    { "Ranking": "04", "Icp": "E21B(土层或岩石的钻进)", "Name": "新疆国利衡清洁能源科技有限公司", "Num": "74" },
    { "Ranking": "05", "Icp": "E21B(土层或岩石的钻进)", "Name": "中国石油集团西部钻探工程有限公司克拉玛依钻井工艺研究院", "Num": "69" },
    { "Ranking": "01", "Icp": "B01D(分离)", "Name": "新疆天业（集团）有限公司", "Num": "45" },
    { "Ranking": "02", "Icp": "B01D(分离)", "Name": "宝钢集团新疆八一钢铁有限公司", "Num": "42" },
    { "Ranking": "03", "Icp": "B01D(分离)", "Name": "新特能源股份有限公司", "Num": "39" },
    { "Ranking": "04", "Icp": "B01D(分离)", "Name": "新疆中泰化学股份有限公司", "Num": "34" },
    { "Ranking": "05", "Icp": "B01D(分离)", "Name": "新疆八一钢铁股份有限公司", "Num": "31" },
    { "Ranking": "01", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "中国石油集团西部钻探工程有限公司", "Num": "74" },
    { "Ranking": "02", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "宝钢集团新疆八一钢铁有限公司", "Num": "48" },
    { "Ranking": "03", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "新疆八一钢铁股份有限公司", "Num": "32" },
    { "Ranking": "04", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "新疆众和股份有限公司", "Num": "22" },
    { "Ranking": "05", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "国网新疆电力公司电力科学研究院", "Num": "17" },
    { "Ranking": "01", "Icp": "C02F(水、废水、污水或污泥的处理)", "Name": "新疆环境工程技术有限责任公司", "Num": "46" },
    { "Ranking": "02", "Icp": "C02F(水、废水、污水或污泥的处理)", "Name": "新疆德蓝股份有限公司", "Num": "41" },
    { "Ranking": "03", "Icp": "C02F(水、废水、污水或污泥的处理)", "Name": "新疆水处理工程技术研究中心有限公司", "Num": "26" },
    { "Ranking": "04", "Icp": "C02F(水、废水、污水或污泥的处理)", "Name": "德蓝水技术股份有限公司", "Num": "25" },
    { "Ranking": "05", "Icp": "C02F(水、废水、污水或污泥的处理)", "Name": "新疆石油勘察设计研究院（有限公司）", "Num": "24" },
    { "Ranking": "01", "Icp": "B65G(运输或贮存装置，例如装载或倾斜用输送机；车间输送机系统；气动管道输送机)", "Name": "宝钢集团新疆八一钢铁有限公司", "Num": "104" },
    { "Ranking": "02", "Icp": "B65G(运输或贮存装置，例如装载或倾斜用输送机；车间输送机系统；气动管道输送机)", "Name": "新疆八一钢铁股份有限公司", "Num": "52" },
    { "Ranking": "03", "Icp": "B65G(运输或贮存装置，例如装载或倾斜用输送机；车间输送机系统；气动管道输送机)", "Name": "新疆天业（集团）有限公司", "Num": "18" },
    { "Ranking": "04", "Icp": "B65G(运输或贮存装置，例如装载或倾斜用输送机；车间输送机系统；气动管道输送机)", "Name": "新疆科立机械设备有限公司", "Num": "16" },
    { "Ranking": "05", "Icp": "B65G(运输或贮存装置，例如装载或倾斜用输送机；车间输送机系统；气动管道输送机)", "Name": "哈密市浩邦建材有限公司", "Num": "15" },
]



var tableItems1_2 = [
    { "Ranking": "01", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "塔里木大学", "Num": "263" },
    { "Ranking": "02", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "石河子大学", "Num": "204" },
    { "Ranking": "03", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "新疆大学", "Num": "131" },
    { "Ranking": "04", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "新疆农业大学", "Num": "79" },
    { "Ranking": "05", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "新疆医科大学", "Num": "46" },
    { "Ranking": "01", "Icp": "A01G(园艺；蔬菜、花卉、稻、果树、葡萄、啤酒花或海菜的栽培；林业；浇水)", "Name": "塔里木大学", "Num": "202" },
    { "Ranking": "02", "Icp": "A01G(园艺；蔬菜、花卉、稻、果树、葡萄、啤酒花或海菜的栽培；林业；浇水)", "Name": "石河子大学", "Num": "195" },
    { "Ranking": "03", "Icp": "A01G(园艺；蔬菜、花卉、稻、果树、葡萄、啤酒花或海菜的栽培；林业；浇水)", "Name": "新疆农业大学", "Num": "107" },
    { "Ranking": "04", "Icp": "A01G(园艺；蔬菜、花卉、稻、果树、葡萄、啤酒花或海菜的栽培；林业；浇水)", "Name": "新疆大学", "Num": "28" },
    { "Ranking": "05", "Icp": "A01G(园艺；蔬菜、花卉、稻、果树、葡萄、啤酒花或海菜的栽培；林业；浇水)", "Name": "新疆医科大学", "Num": "10" },
    { "Ranking": "01", "Icp": "A01C(种植；播种；施肥)", "Name": "石河子大学", "Num": "323" },
    { "Ranking": "02", "Icp": "A01C(种植；播种；施肥)", "Name": "塔里木大学", "Num": "86" },
    { "Ranking": "03", "Icp": "A01C(种植；播种；施肥)", "Name": "新疆农业大学", "Num": "60" },
    { "Ranking": "04", "Icp": "A01C(种植；播种；施肥)", "Name": "新疆大学", "Num": "6" },
    { "Ranking": "05", "Icp": "A01C(种植；播种；施肥)", "Name": "石河子大学工学院", "Num": "4" },
    { "Ranking": "01", "Icp": "A01D(收获；割草)", "Name": "石河子大学", "Num": "303" },
    { "Ranking": "02", "Icp": "A01D(收获；割草)", "Name": "塔里木大学", "Num": "81" },
    { "Ranking": "03", "Icp": "A01D(收获；割草)", "Name": "新疆农业大学", "Num": "64" },
    { "Ranking": "04", "Icp": "A01D(收获；割草)", "Name": "新疆大学", "Num": "10" },
    { "Ranking": "05", "Icp": "A01D(收获；割草)", "Name": "新疆农业职业技术学院", "Num": "6" },
    { "Ranking": "01", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "新疆医科大学", "Num": "147" },
    { "Ranking": "02", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "石河子大学", "Num": "78" },
    { "Ranking": "03", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "新疆医科大学第一附属医院", "Num": "42" },
    { "Ranking": "04", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "新疆农业大学", "Num": "36" },
    { "Ranking": "05", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "塔里木大学", "Num": "35" },
]



var tableItems1_3 = [
    { "Ranking": "01", "Icp": "A01G(园艺；蔬菜、花卉、稻、果树、葡萄、啤酒花或海菜的栽培；林业；浇水)", "Name": "中国科学院新疆生态与地理研究所", "Num": "280" },
    { "Ranking": "02", "Icp": "A01G(园艺；蔬菜、花卉、稻、果树、葡萄、啤酒花或海菜的栽培；林业；浇水)", "Name": "新疆农垦科学院", "Num": "159" },
    { "Ranking": "03", "Icp": "A01G(园艺；蔬菜、花卉、稻、果树、葡萄、啤酒花或海菜的栽培；林业；浇水)", "Name": "新疆林业科学院", "Num": "87" },
    { "Ranking": "04", "Icp": "A01G(园艺；蔬菜、花卉、稻、果树、葡萄、啤酒花或海菜的栽培；林业；浇水)", "Name": "新疆农业科学院农业机械化研究所", "Num": "55" },
    { "Ranking": "05", "Icp": "A01G(园艺；蔬菜、花卉、稻、果树、葡萄、啤酒花或海菜的栽培；林业；浇水)", "Name": "新疆林科院造林治沙研究所", "Num": "44" },
    { "Ranking": "01", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "中国科学院新疆生态与地理研究所", "Num": "98" },
    { "Ranking": "02", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "中国科学院新疆理化技术研究所", "Num": "89" },
    { "Ranking": "03", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "新疆维吾尔自治区产品质量监督检验研究院", "Num": "64" },
    { "Ranking": "04", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "新疆农垦科学院", "Num": "44" },
    { "Ranking": "05", "Icp": "G01N(借助于测定材料的化学或物理性质来测试或分析材料)", "Name": "新疆水利水电科学研究院", "Num": "26" },
    { "Ranking": "01", "Icp": "E21B(土层或岩石的钻进)", "Name": "新疆石油管理局钻井工艺研究院", "Num": "203" },
    { "Ranking": "02", "Icp": "E21B(土层或岩石的钻进)", "Name": "新疆石油管理局采油工艺研究院", "Num": "86" },
    { "Ranking": "03", "Icp": "E21B(土层或岩石的钻进)", "Name": "西部钻探克拉玛依钻井工艺研究院", "Num": "45" },
    { "Ranking": "04", "Icp": "E21B(土层或岩石的钻进)", "Name": "吐哈石油勘探开发指挥部工程技术研究院", "Num": "21" },
    { "Ranking": "05", "Icp": "E21B(土层或岩石的钻进)", "Name": "吐哈石油勘探开发指挥部钻采工艺研究院", "Num": "15" },
    { "Ranking": "01", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "中国科学院新疆理化技术研究所", "Num": "157" },
    { "Ranking": "02", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "新疆维吾尔自治区药物研究所", "Num": "107" },
    { "Ranking": "03", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "新疆维吾尔自治区中药民族药研究所", "Num": "32" },
    { "Ranking": "04", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "新疆维吾尔自治区维吾尔医药研究所", "Num": "32" },
    { "Ranking": "05", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "中国科学院新疆化学研究所", "Num": "22" },
    { "Ranking": "01", "Icp": "C12N(微生物或酶；其组合物)", "Name": "新疆农业科学院微生物应用研究所", "Num": "105" },
    { "Ranking": "02", "Icp": "C12N(微生物或酶；其组合物)", "Name": "新疆农垦科学院", "Num": "36" },
    { "Ranking": "03", "Icp": "C12N(微生物或酶；其组合物)", "Name": "中国科学院新疆生态与地理研究所", "Num": "32" },
    { "Ranking": "04", "Icp": "C12N(微生物或酶；其组合物)", "Name": "新疆维吾尔自治区畜牧科学院中国-澳大利亚绵羊育种研究中心", "Num": "31" },
    { "Ranking": "05", "Icp": "C12N(微生物或酶；其组合物)", "Name": "新疆农业科学院微生物应用研究所（中国新疆-亚美尼亚生物工程研究开发中心）", "Num": "24" },
]



var tableItems1_4 = [
    { "Ranking": "01", "Icp": "A61B(诊断；外科；鉴定)", "Name": "新疆维吾尔自治区人民医院", "Num": "129" },
    { "Ranking": "02", "Icp": "A61B(诊断；外科；鉴定)", "Name": "乌鲁木齐市友谊医院", "Num": "7" },
    { "Ranking": "03", "Icp": "A61B(诊断；外科；鉴定)", "Name": "喀什地区第一人民医院", "Num": "7" },
    { "Ranking": "04", "Icp": "A61B(诊断；外科；鉴定)", "Name": "中国人民解放军兰州军区乌鲁木齐总医院", "Num": "5" },
    { "Ranking": "05", "Icp": "A61B(诊断；外科；鉴定)", "Name": "新疆阿克苏地区第一人民医院", "Num": "4" },
    { "Ranking": "01", "Icp": "A61M(将介质输入人体内或输到人体上的器械)", "Name": "新疆维吾尔自治区人民医院", "Num": "110" },
    { "Ranking": "02", "Icp": "A61M(将介质输入人体内或输到人体上的器械)", "Name": "沙湾县人民医院", "Num": "6" },
    { "Ranking": "03", "Icp": "A61M(将介质输入人体内或输到人体上的器械)", "Name": "新疆生产建设兵团北屯医院", "Num": "5" },
    { "Ranking": "04", "Icp": "A61M(将介质输入人体内或输到人体上的器械)", "Name": "喀什地区第一人民医院", "Num": "4" },
    { "Ranking": "05", "Icp": "A61M(将介质输入人体内或输到人体上的器械)", "Name": "新疆维吾尔自治区胸科医院", "Num": "3" },
    { "Ranking": "01", "Icp": "A61G(专门适用于病人或残疾人的运输工具、专用运输工具或起居设施)", "Name": "新疆维吾尔自治区人民医院", "Num": "75" },
    { "Ranking": "02", "Icp": "A61G(专门适用于病人或残疾人的运输工具、专用运输工具或起居设施)", "Name": "喀什地区第一人民医院", "Num": "7" },
    { "Ranking": "03", "Icp": "A61G(专门适用于病人或残疾人的运输工具、专用运输工具或起居设施)", "Name": "克拉玛依市中心医院", "Num": "6" },
    { "Ranking": "04", "Icp": "A61G(专门适用于病人或残疾人的运输工具、专用运输工具或起居设施)", "Name": "新疆维吾尔自治区人民医院北院", "Num": "3" },
    { "Ranking": "05", "Icp": "A61G(专门适用于病人或残疾人的运输工具、专用运输工具或起居设施)", "Name": "博尔塔拉蒙古自治州人民医院", "Num": "2" },
    { "Ranking": "01", "Icp": "A61F(可植入血管内的滤器；假体；为人体管状结构提供开口、或防止其塌陷的装置，例如支架)", "Name": "新疆维吾尔自治区人民医院", "Num": "61" },
    { "Ranking": "02", "Icp": "A61F(可植入血管内的滤器；假体；为人体管状结构提供开口、或防止其塌陷的装置，例如支架)", "Name": "中国人民解放军新疆军区总医院", "Num": "6" },
    { "Ranking": "03", "Icp": "A61F(可植入血管内的滤器；假体；为人体管状结构提供开口、或防止其塌陷的装置，例如支架)", "Name": "中国人民解放军兰州军区乌鲁木齐总医院", "Num": "4" },
    { "Ranking": "04", "Icp": "A61F(可植入血管内的滤器；假体；为人体管状结构提供开口、或防止其塌陷的装置，例如支架)", "Name": "新疆维吾尔自治区喀什地区第二人民医院", "Num": "3" },
    { "Ranking": "05", "Icp": "A61F(可植入血管内的滤器；假体；为人体管状结构提供开口、或防止其塌陷的装置，例如支架)", "Name": "克拉玛依市中心医院", "Num": "3" },
    { "Ranking": "01", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "中国人民解放军兰州军区乌鲁木齐总医院", "Num": "14" },
    { "Ranking": "02", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "新疆维吾尔自治区中医医院", "Num": "12" },
    { "Ranking": "03", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "新疆吐鲁番地区维吾尔医医院", "Num": "8" },
    { "Ranking": "04", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "博尔塔拉蒙古自治州蒙医医院", "Num": "6" },
    { "Ranking": "05", "Icp": "A61K(医用、牙科用或梳妆用的配制品)", "Name": "新疆阿勒泰地区哈萨克医医院", "Num": "5" },
]


//加载固定数据
var data1 = 5087
var data2 = 76
var data3 = 87
var data4 = 215
var data5 = 10476
var data6 = 604
var data7 = 34
var data8 = 571


var seriesname = ['许可', '转让', '质押']
var yname = ['企业', '大专院校', '科研机构', '机关团体']
var seriesvalu1 = ['1009', '26', '59', '16'];
var seriesvalu2 = ['9164', '578', '512', '18'];
var seriesvalu3 = ['303', '0', '0', '0'];
let qiyevalue = [{
    name: '许可',
    value: 1009,
},
{
    name:'转让',
    value:9164
},
{
    name:'质押',
    value:303
}
]
let dazhuanvalue = [{
    name: '许可',
    value: 26,
},
{
    name:'转让',
    value:578
},
{
    name:'质押',
    value:0
}
]
let keyanvalue = [{
    name: '许可',
    value: 59,
},
{
    name:'转让',
    value:512
},
{
    name:'质押',
    value:0
}
]
let jiguanvalue = [{
    name: '许可',
    value: 16,
},
{
    name:'转让',
    value:18
},
{
    name:'质押',
    value:0
}
]


//左侧柱图	
var leftname = ['企业', '大专院校', '科研机构', '机关团体']
var leftvalue = ['43050', '7099', '4552', '1047']
var leftvalue2 = ['5087', '76', '215', '87']
var leftdisplay1 = ['5087', '0', '0', '0']
var leftdisplay2 = ['', '76', '', '']
var leftdisplay3 = ['', '', '215', '']
var leftdisplay4 = ['', '', '', '87']




//右侧IPC分类
let qydata=[
    {name:'E21B(土层或岩石的钻进)',value:'3279'},
    {name:'B01D(分离)',value:'1744'},
    {name:'G01N(借助于测定材料的化学或物理性质来测试或分析材料)',value:'1115'},
    {name:'C02F(水、废水、污水或污泥的处理)',value:'1113'},
    {name:'B65G(运输或贮存装置，例如装载或倾斜用输送机；车间输送机系统；气动管道输送机)',value:'995'}
]
let kyjgdata=[
    {name:'A01G(园艺；蔬菜、花卉、稻、果树、葡萄、啤酒花或海菜的栽培；林业；浇水)',value:'1087'},
    {name:'G01N(借助于测定材料的化学或物理性质来测试或分析材料)',value:'586'},
    {name:'E21B(土层或岩石的钻进)',value:'421'},
    {name:'A61K(医用、牙科用或梳妆用的配制品)',value:'400'},
    {name:'C12N(微生物或酶；其组合物)',value:'359'}
]
let dzyxdata=[
    {name:'G01N(借助于测定材料的化学或物理性质来测试或分析材料)',value:'859'},
    {name:'A01G(园艺；蔬菜、花卉、稻、果树、葡萄、啤酒花或海菜的栽培；林业；浇水)',value:'583'},
    {name:'A01C(种植；播种；施肥)',value:'485'},
    {name:'A01D(收获；割草)',value:'484'},
    {name:'A61K(医用、牙科用或梳妆用的配制品)',value:'430'},
]

let jgttdata=[
    {name:'A61B(诊断；外科；鉴定)',value:'204'},
    {name:'A61M(将介质输入人体内或输到人体上的器械)',value:'166'},
    {name:'A61G(专门适用于病人或残疾人的运输工具、专用运输工具或起居设施)',value:'108'},
    {name:'A61F(可植入血管内的滤器；假体；为人体管状结构提供开口、或防止其塌陷的装置，例如支架)',value:'95'},
    {name:'A61K(医用、牙科用或梳妆用的配制品)',value:'72'},
]





//地图数据	
var mapData = [
    { name: "沙依巴克区", value: [2264, 52, 159, 38,2513] },
{ name: "新市区", value: [422, 4, 20, 5,451] },
{ name: "头屯河区", value: [151, 1, 9, 3,164] },
{ name: "天山区", value: [178, 0, 4, 1,183] },
{ name: "水磨沟区", value: [725, 6, 8, 4,741] },
{ name: "达坂城区", value: [106, 1, 0, 2,109] },
{ name: "米东区", value: [345, 3, 8, 7,363] },
{ name: "乌鲁木齐县", value: [261, 7, 6, 7,281] }
]



var color1Divide = "乌鲁木齐市";
var color2Divide = "克拉玛依市";
var color3Divide = "吐鲁番市";
var color1Value = "#DD3F48";
var color2Value = "#CDB87A";
var color3Value = "#72B6DB";

export default {
    mapData,
    tableItems1_4,
    tableItems1_1,
    tableItems1_2,
    tableItems1_3,
    qiyevalue,
    dazhuanvalue,
    jiguanvalue,
    keyanvalue,
    leftname,
    leftvalue,
    leftvalue2,
    qydata,
    kyjgdata,
    dzyxdata,
    jgttdata
}



