import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataV from '@jiaminghi/data-view'
import echarts from 'echarts'
import './assets/global.css'
import '@/common/flexible.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import totalData from "../public/jsData/total"
import axios from "axios"

Vue.prototype.$axios=axios

Vue.prototype.$totalData= totalData
Vue.prototype.$echarts = echarts
Vue.use(dataV)
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
