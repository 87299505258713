<template>
  <div id="compontent">
    <dv-border-box-7 backgroundColor="rgba(93, 93, 95,0.3)">
      <div class="out">
        <div class="title">
          <span class="fixed-yellow">近五</span>年专利运营量趋势
        </div>
        <div class="content">
          <echart
            id="lineChartFF"
            :options="options"
            :data="needData.yearValue"
            :isChange="true"
          />
        </div>
      </div>
    </dv-border-box-7>
  </div>
</template>
<script>
import data from "../../../public/jsData/patent";
import echart from "../../components/echarts/echart.vue";
export default {
  components: { echart },
  data() {
    return {
      needData: "",
      options: "",
    };
  },
  mounted() {
    this.needData = data;
  },
  watch: {
    needData: {
      handler: function (val) {
        this.options = {
    tooltip: {
        trigger: 'axis',
       confine:true,
       backgroundColor:"rgba(255,255,255,0.3)",
       textStyle:{
         color:'#FFF'
       }
    },
    legend: {
      x:'right',
      data:this.needData.displayname,
      textStyle:{
            color:"#FFF",
            fontSize:"0.175rem"
        }
  },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true
    },
   xAxis: [
        {
            type: 'category',
            boundaryGap: false,
            data: this.needData.yearValue,
            axisLabel:{
                fontSize:"0.15rem"
            }
        }
    ],
    yAxis: [
        {
            type: 'value',
              axisLabel:{
                fontSize:"0.15rem"
            }
        }
    ],
    series: [
        {
            name: this.needData.displayname[0],
            type: 'line',
            stack: 'a',
            data:this.needData.displayvalue1
},
        {
            name:  this.needData.displayname[1],
            type: 'line',
            stack: 'b',
            data: this.needData.displayvalue2
        },
        {
            name: this.needData.displayname[2],
            type: 'line',
            stack: '2',
            data: this.needData.displayvalue3
        }
    ]
};
      },
    },
  },
};
</script>
<style scoped>
#compontent {
  height: 96%;
  width: 100%;
}
.out {
  height: 95%;
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 10%;
  font-size: 0.225rem /* 18px -> .225rem */;
  font-weight: bold;
  line-height: 40px;
}
.content {
  height: 88%;
}
</style>