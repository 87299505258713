<template>
    <div id="compontent">
        <dv-border-box-6
      width="90%"
      height="90%"
      id="border"
      backgroundColor="RGBA(77, 144, 254,0.2)"
    >
    <div class="out">
        <div class="title"><span class="fixed-yellow">近五</span>年专利授权量/商标注册量</div>
        <div class="content">
          <echart id="BarB" :options="options" :data="data" :isChange="false" />
        </div>
    </div>
    </dv-border-box-6>
    </div>
</template>
<script>
import Echart from "../../components/echarts/echart.vue";
export default {
  components:{Echart},
    name:"barChartNum",
    data() {
      return {
        options:"",
        data:""
      }
    },
    mounted(){
      this.$nextTick(function(){
          this.data=[[14096,14761,17527,22309,31400],[4365,5195,6352,5983,7642]]
          this.options={
    tooltip: {
         trigger:'axis',
      backgroundColor:"rgba(255,255,255,0.5)",
      textStyle:{
          color:"#FFF"
      },
        axisPointer: {
            type: 'cross',
            crossStyle: {
                color: '#999'
            }
        }
    },
     grid:{
        top:40,
        x:70,
        x2:45,
        y2:30
    },
    xAxis: [
        {
            type: 'category',
            data: ['2016年','2017年','2018年','2019年','2020年'],
            axisPointer: {
                type: 'shadow'
            },
            axisLabel:{
          fontSize:'0.15rem'
        }
        }
    ],
    yAxis: [
        {
            nameTextStyle:{
            color:'#5c99c5',
            fontSize:"0.15rem"
        },
            type: 'value',
            name: '数量(件)',
            axisLabel:{
          fontSize:'0.15rem'
        }
        },
        
    ],
    series: [
        {
            name: '商标注册量',
            type: 'bar',
            data: [14096,14761,17527,22309,31400]
        },
        {
            name: '专利授权量',
            type: 'bar',
            data: [4365,5195,6352,5983,7642]
        }
    ]
};
      })
    }
}
</script>
<style scoped>
    #compontent{
        width: 100%;
        height: 95%;
    }
    .out{
         width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
    }
    .title {
  height: 12%;
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
}
.content {
  height: 83%;
  display: flex;
  justify-content: space-around;
}
</style>