<template>
  <div id="compontent">
    <dv-border-box-10
      width="90%"
      height="95%"
      backgroundColor="RGBA(77, 144, 254,0.2)"
      id="border"
    >
      <dv-scroll-board :config="config" style="width: 95%; height: 80%" />
    </dv-border-box-10>
  </div>
</template>
<script>
export default {
  data() {
    return {
      config: {
        header: ["排名", "机构名称", "专利授权量"],
        data: [
          ["<span style='font-size:14px'>行1列1</span>", "行1列2", "行1列3"],
          ["行2列1", "行2列2", "行2列3"],
          ["行3列1", "行3列2", "行3列3"],
          ["行4列1", "行4列2", "行4列3"],
          ["行5列1", "行5列2", "行5列3"],
          ["行6列1", "行6列2", "行6列3"],
          ["行7列1", "行7列2", "行7列3"],
          ["行8列1", "行8列2", "行8列3"],
          ["行9列1", "行9列2", "行9列3"],
          ["行10列1", "行10列2", "行10列3"],
        ],
        waitTime: 1000,
        headerHeight: 40,
        oddRowBGC: "RGBA(77, 144, 254,0.1)",
        evenRowBGC: "RGBA(10, 39, 50,0.2)",
        headerBGC: "RGBA(77, 144, 254,0.8)",
      },
    };
  },
  mounted() {
    let data = this.$store.state.totalData.default.tableItems;
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (i < 3) {
          let a = "<span style='font-size:0.25rem;color:#fac858'>" + data[i][j] + "</span>";
        data[i][j] = a;
        } else {
          let a = "<span style='font-size:0.2rem'>" + data[i][j] + "</span>";
        data[i][j] = a;
        }

      }
    }
    this.config = {
      header: ["<span style='font-size:0.28rem'>排名</span>", "<span style='font-size:0.28rem'>机构名称</span>", "<span style='font-size:0.28rem'>专利授权量</span>"],
      data: data,
      waitTime: 1000,
      headerHeight: 40,
      oddRowBGC: "RGBA(77, 144, 254,0.1)",
      evenRowBGC: "RGBA(10, 39, 50,0.2)",
      headerBGC: "RGBA(77, 144, 254,0.4)",
      align: ["center", "center", "center"],
          columnWidth:[100,500,140]
    };
  },
  watch: {},
};
</script>
<style>
#compontent {
  width: 100%;
  height: 95%;
}
.border-box-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
#border {
}
.header {
  font-weight: bold;
}
</style>