var tableItems1_1 = [
    {"Ranking":"01","Name":"北京市","Num":"1058"},
    {"Ranking":"02","Name":"未知","Num":"591"},
    {"Ranking":"03","Name":"浙江省","Num":"292"},
    {"Ranking":"04","Name":"广东省","Num":"251"},
    {"Ranking":"05","Name":"江苏省","Num":"232"},
    {"Ranking":"06","Name":"上海市","Num":"106"},
    {"Ranking":"07","Name":"福建省","Num":"100"},
    {"Ranking":"08","Name":"四川省","Num":"93"},
    {"Ranking":"09","Name":"山东省","Num":"85"},
    {"Ranking":"10","Name":"安徽省","Num":"69"},
    {"Ranking":"11","Name":"河南省","Num":"62"},
    {"Ranking":"12","Name":"新疆维吾尔自治区","Num":"56"},
    {"Ranking":"13","Name":"河北省","Num":"38"},
    {"Ranking":"14","Name":"陕西省","Num":"35"},
    {"Ranking":"15","Name":"湖北省","Num":"28"},
    {"Ranking":"15","Name":"甘肃省","Num":"28"},
    {"Ranking":"15","Name":"辽宁省","Num":"28"},
    {"Ranking":"18","Name":"重庆市","Num":"23"},
    {"Ranking":"19","Name":"湖南省","Num":"22"},
    {"Ranking":"19","Name":"江西省","Num":"22"},
    {"Ranking":"21","Name":"天津市","Num":"21"},
    {"Ranking":"22","Name":"山西省","Num":"19"},
    {"Ranking":"23","Name":"黑龙江省","Num":"18"},
    {"Ranking":"24","Name":"广西壮族自治区","Num":"14"},
    {"Ranking":"25","Name":"内蒙古自治区","Num":"13"},
    {"Ranking":"26","Name":"吉林省","Num":"11"},
    {"Ranking":"26","Name":"青海省","Num":"11"},
    {"Ranking":"28","Name":"贵州省","Num":"10"},
    {"Ranking":"29","Name":"云南省","Num":"5"},
    {"Ranking":"29","Name":"海南省","Num":"5"},
    {"Ranking":"31","Name":"西藏自治区","Num":"4"},
    {"Ranking":"32","Name":"宁夏回族自治区","Num":"3"},
    ]
    
    
    var tableItems1_2 = [
    {"Ranking":"01","Name":"北京市","Num":"1732"},
    {"Ranking":"02","Name":"浙江省","Num":"375"},
    {"Ranking":"03","Name":"广东省","Num":"354"},
    {"Ranking":"04","Name":"江苏省","Num":"293"},
    {"Ranking":"05","Name":"四川省","Num":"243"},
    {"Ranking":"06","Name":"山东省","Num":"196"},
    {"Ranking":"07","Name":"福建省","Num":"144"},
    {"Ranking":"08","Name":"上海市","Num":"119"},
    {"Ranking":"09","Name":"湖南省","Num":"108"},
    {"Ranking":"10","Name":"河南省","Num":"100"},
    {"Ranking":"11","Name":"重庆市","Num":"99"},
    {"Ranking":"12","Name":"未知","Num":"91"},
    {"Ranking":"13","Name":"安徽省","Num":"89"},
    {"Ranking":"14","Name":"天津市","Num":"81"},
    {"Ranking":"15","Name":"湖北省","Num":"73"},
    {"Ranking":"15","Name":"河北省","Num":"73"},
    {"Ranking":"17","Name":"陕西省","Num":"70"},
    {"Ranking":"18","Name":"新疆维吾尔自治区","Num":"62"},
    {"Ranking":"19","Name":"辽宁省","Num":"49"},
    {"Ranking":"20","Name":"黑龙江省","Num":"48"},
    {"Ranking":"21","Name":"山西省","Num":"35"},
    {"Ranking":"22","Name":"江西省","Num":"34"},
    {"Ranking":"23","Name":"广西壮族自治区","Num":"27"},
    {"Ranking":"24","Name":"内蒙古自治区","Num":"24"},
    {"Ranking":"25","Name":"甘肃省","Num":"23"},
    {"Ranking":"26","Name":"宁夏回族自治区","Num":"18"},
    {"Ranking":"27","Name":"吉林省","Num":"14"},
    {"Ranking":"28","Name":"云南省","Num":"6"},
    {"Ranking":"29","Name":"贵州省","Num":"5"},
    {"Ranking":"30","Name":"台湾省","Num":"4"},
    {"Ranking":"31","Name":"青海省","Num":"3"},
    {"Ranking":"32","Name":"海南省","Num":"1"},
    ]
    
    
    var tableItems1_3 = [
    {"Ranking":"01","Name":"乌鲁木齐市","Num":"2448"},
    {"Ranking":"02","Name":"克拉玛依市","Num":"534"},
    {"Ranking":"03","Name":"昌吉回族自治州","Num":"510"},
    {"Ranking":"04","Name":"石河子市","Num":"365"},
    {"Ranking":"05","Name":"伊犁哈萨克自治州","Num":"139"},
    {"Ranking":"06","Name":"巴音郭楞蒙古自治州","Num":"120"},
    {"Ranking":"07","Name":"哈密地区","Num":"110"},
    {"Ranking":"08","Name":"吐鲁番地区","Num":"109"},
    {"Ranking":"09","Name":"塔城地区","Num":"94"},
    {"Ranking":"10","Name":"喀什地区","Num":"73"},
    {"Ranking":"11","Name":"阿克苏地区","Num":"39"},
    {"Ranking":"11","Name":"阿勒泰地区","Num":"39"},
    {"Ranking":"13","Name":"博尔塔拉蒙古自治州","Num":"37"},
    {"Ranking":"14","Name":"和田地区","Num":"23"},
    {"Ranking":"15","Name":"阿拉尔市","Num":"19"},
    {"Ranking":"16","Name":"五家渠市","Num":"17"},
    {"Ranking":"17","Name":"克孜勒苏柯尔克孜自治州","Num":"4"},
    {"Ranking":"17","Name":"图木舒克市","Num":"4"},
    ]
    
    
    
    var tableItems2 = [
    {"Ranking":1,"Name":"美克国际家居用品股份有限公司","Num":1386},
    {"Ranking":2,"Name":"中国石油集团西部钻探工程有限公司","Num":626},
    {"Ranking":3,"Name":"乌鲁木齐九品芝麻信息科技有限公司","Num":329},
    {"Ranking":"04","Name":"新疆八一钢铁股份有限公司","Num":270},
    {"Ranking":"05","Name":"宝钢集团新疆八一钢铁有限公司","Num":249},
    {"Ranking":"06","Name":"西部钻探克拉玛依钻井工艺研究院","Num":238},
    {"Ranking":"07","Name":"新疆石油管理局钻井工艺研究院","Num":224},
    {"Ranking":"08","Name":"国网新疆电力公司","Num":210},
    {"Ranking":"09","Name":"国网新疆电力有限公司","Num":157},
    {"Ranking":"10","Name":"新疆万佳万康食品科技有限公司","Num":156},
    {"Ranking":"11","Name":"新疆医科大学","Num":143},
    {"Ranking":"12","Name":"国家电网公司","Num":130},
    {"Ranking":"13","Name":"新疆蓝山屯河化工有限公司","Num":127},
    {"Ranking":"14","Name":"新疆天业(集团)有限公司","Num":99},
    {"Ranking":"15","Name":"新疆中泰化学股份有限公司","Num":90},
    {"Ranking":"16","Name":"天能化工有限公司","Num":"72"},
    {"Ranking":"17","Name":"新疆通奥油田技术服务有限公司","Num":63},
    {"Ranking":"17","Name":"新特能源股份有限公司","Num":63},
    {"Ranking":"19","Name":"国家电网有限公司","Num":62},
    {"Ranking":"20","Name":"新疆电力公司电力科学研究院","Num":58},
    {"Ranking":"20","Name":"新疆金风科技股份有限公司","Num":58},
    {"Ranking":"20","Name":"国网新疆电力有限公司电力科学研究院","Num":58},
    {"Ranking":"23","Name":"新疆医科大学第一附属医院","Num":57},
    {"Ranking":"23","Name":"石河子大学","Num":57},
    {"Ranking":"23","Name":"新疆机械研究院股份有限公司","Num":57},
    {"Ranking":"23","Name":"新疆天业（集团）有限公司","Num":57},
    {"Ranking":"27","Name":"中国科学院新疆理化技术研究所","Num":55},
    {"Ranking":"28","Name":"新疆广汇中化能源技术开发有限公司","Num":54},
    {"Ranking":"28","Name":"新疆广汇煤炭清洁炼化有限责任公司","Num":54},
    {"Ranking":"30","Name":"克拉玛依市建业能源股份有限公司","Num":53},
    ]
    
    
     //中23国民经济分类饼图
     let gmjjdata=[
         {name:'A 农、林、牧、渔业',value:"110"},
         {name:"B 采矿业",value:"69"},
         {name:"C 制造业",value:"5172"},
         {name:"D 电力、热力、燃气及水生产和供应业",value:"295"},
         {name:"E 建筑业",value:"313"},
         {name:"I 信息传输、软件和信息技术服务业",value:"44"},
         {name:"O 居民服务、修理和其他服务业",value:"194"}
     ]
     let gmjjdataWG=[
        {name:"13 农副食品加工业",value:"12"},
        {name:"17 纺织业",value:"58"},
        {name:"19 皮革、毛皮、羽毛及其制品和制鞋业",value:"70"},
        {name:"21 家具制造业",value:"1340"},
        {name:"23 印刷和记录媒介复制业",value:"12"},
        {name:"24 文教、工美、体育和娱乐用品制造业",value:"15"},
        {name:"29 橡胶和塑料制品业",value:"193"},
        {name:"30 非金属矿物制品业",value:"1339"},
        {name:"31 黑色金属冶炼和压延加工业",value:"33"},
        {name:"33 金属制品业",value:"447"},
        {name:"34 通用设备制造业",value:"101"},
        {name:"35 专用设备制造业",value:"79"},
        {name:"36 汽车制造业",value:"8"},
        {name:"37 铁路、船舶、航空航天和其他运输设备制造业",value:"8"},
        {name:"38 电气机械和器材制造业",value:"84"},
        {name:"39 计算机、通信和其他电子设备制造业",value:"47"},
        {name:"40 仪器仪表制造业",value:"1"},
     ]
    
    //中1的运营趋势
    var displayname =	 ['许可','质押','转让'];
    var displayvalue1 =	[31, 28, 6, 54, 7]
    var displayvalue2 =	[22, 12, 46, 108, 38]
    var displayvalue3 =	[535, 1059, 2534, 1335, 1608]
    var yearValue =	[2016, 2017, 2018, 2019, 2020]
    
     //加载固定数据
    var data1 = 	11744
    var data2 = 	1066
    var data3 = 	10360
    var data4 = 	318
    //中间地图展示
    
    var mapData = [	
        { name: "黑龙江省", value: [7,13,0,20] },
        { name: "广东省", value: [34,219,0,253] },
        { name: "西藏自治区", value: [0,4,0,4] },
        { name: "河北省", value: [7,32,0,39] },
        { name: "新疆维吾尔自治区", value: [874,7914,13,8501] },
        { name: "福建省", value: [0,95,5,100]},
        { name: "广西壮族自治区", value: [0,14,0,14] },
        { name: "江苏省", value: [15,217,0,232]},
        { name: "澳门特别行政区", value: [0,0,0,0] },
        { name: "辽宁省", value: [2,28,0,30] },
        { name: "内蒙古自治区", value: [0,13,0,13] },
        { name: "安徽省", value: [12,58,0,70] },
        { name: "云南省", value: [1,4,0,5] },
        { name: "浙江省", value: [87,207,0,294] },
        { name: "重庆市", value: [0,23,0,23] },
        { name: "香港特别行政区", value: [0,0,0,0] },
        { name: "河南省", value: [7,55,0,62] },
        { name: "贵州省", value: [1,10,0,11] },
        { name: "山东省", value: [2,87,0,89] },
        { name: "陕西省", value: [8,27,0,35] },
        { name: "宁夏回族自治区", value: [0,3,0,3] },
        { name: "湖北省", value: [2,28,0,30] },
        { name: "山西省", value: [0,19,0,19] },
        { name: "北京市", value: [55,1005,0,1060] },
        { name: "江西省", value: [4,18,0,22]},
        { name: "海南省", value: [0,5,0,5] },
        { name: "湖南省", value: [3,20,0,23] },
        { name: "吉林省", value: [1,10,0,11] },
        { name: "上海市", value: [5,101,0,106] },
        { name: "台湾省", value: [0,0,0,0] },
        { name: "青海省", value: [0,11,0,11] },
        { name: "天津市", value: [3,18,0,21] },
        { name: "甘肃省", value: [4,27,0,31] },
        { name: "四川省", value: [22,72,0,94] }
         ]
    
    
    
    
    var color1Divide = "";
    var color2Divide = "";
    var color3Divide = "";
    var color1Value = "#DD3F48";
    var color2Value = "#CDB87A";
    var color3Value = "#72B6DB";
    
    export default{
        tableItems1_1,
        tableItems1_2,
        tableItems1_3,
        gmjjdata,
        gmjjdataWG,
        mapData,
        displayname,
        yearValue,
        displayvalue1,
        displayvalue2,
        displayvalue3,
        tableItems2
    }