<template>
  <div id="compontent">
    <div class="item" style="height: 30%">
      <dv-border-Box-1 backgroundColor="RGBA(77, 144, 254,0.2)">
        <div
          style="
            width: 90%;
            height: 90%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          "
        >
          <div class="title">
            <span class="fixed-yellow">专利</span>授权类型分布
          </div>
          <div class="content">
            <echart
              id="pieDD"
              :data="pieOneData"
              :options="optionsA"
              :isChange="true"
            />
          </div>
        </div>
      </dv-border-Box-1>
    </div>
    <div class="item" style="height: 30%">
      <dv-border-Box-1 backgroundColor="RGBA(77, 144, 254,0.2)">
        <div
          style="
            width: 90%;
            height: 90%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          "
        >
          <div class="title">
            <span class="fixed-yellow">专利</span>运营情况
          </div>
          <div class="content">
            <echart
              :isChange="true"
              id="pieEE"
              :data="pieTwoData"
              :options="optionsB"
            />
          </div>
        </div>
      </dv-border-Box-1>
    </div>
    <div class="item" style="height: 38%">
      <dv-border-Box-1 backgroundColor="RGBA(77, 144, 254,0.2)">
        <div
          style="
            width: 90%;
            height: 90%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          "
        >
          <div class="title">
            <span class="fixed-yellow">授权</span>专利国民经济行业分布
          </div>
          <div class="content" style="display:flex;justify-content: space-around;">
            <div class="child">
              <echart id="pieFF" :isChange="true" :data="pieThreeData" :options="optionsC" />
            </div>
            <div class="child">
              <echart id="pieGG" :isChange="true" :data="pieFourData" :options="optionsD" />
            </div>
          </div>
        </div>
      </dv-border-Box-1>
    </div>
  </div>
</template>
<script>
import echart from "../../components/echarts/echart.vue";
import data from "../../../public/jsData/borad.js";
export default {
  components: { echart },
  data() {
    return {
      needData: "",
      pieOneData: "",
      optionsA: "",
      pieTwoData: "",
      optionsB: "",
      pieThreeData:"",
      pieFourData:"",
      optionsC:"",
      optionsD:""
    };
  },
  mounted() {
    this.needData = data;
    this.pieOneData = data.zllxdata;
    this.pieTwoData = data.zlyydata;
    this.pieThreeData=data.gmjjdata;
    this.pieFourData=data.gmjjdataWG
  },
  watch: {
    needData: {
      handler: function (val) {
        if (val) {
          this.optionsA = {
            tooltip: {
              trigger: "item",
              formatter: function (res) {
                return (
                  '<span style="color:#fff;font-size:0.2rem">' +
                  res.name +
                  '</span><br/><span style="font-size:0.175rem;color:#FFF">数量：</span><span style="color:red;font-weight:bold;font-size:0.2rem">' +
                  res.value +
                  "</span>"
                );
              },
              backgroundColor:'rgba(0,0,0,0.5)'
            },
            series: [
              {
                name: "数量",
                type: "pie",
                radius: ["40%", "70%"],
                avoidLabelOverlap: false,
                labelLine: {
                  show: false,
                },
                data: this.pieOneData,
                label: {
                  fontSize: "0.2rem",
                  color: "#FFF",
                },
              },
            ],
          };
          this.optionsB = {
            tooltip: {
              trigger: "item",
              formatter: function (res) {
                return (
                  '<span style="color:#fff;font-size:0.2rem">' +
                  res.name +
                  '</span><br/><span style="font-size:0.175rem;color:#fff">数量：</span><span style="color:red;font-weight:bold;font-size:0.2rem">' +
                  res.value +
                  "</span>"
                );
              },
              backgroundColor:'rgba(0,0,0,0.5)'
            },
            series: [
              {
                name: "数量",
                type: "pie",
                radius: ["40%", "70%"],
                avoidLabelOverlap: false,
                labelLine: {
                  show: false,
                },
                data: this.pieTwoData,
                label: {
                  fontSize: "0.2rem",
                  color: "#FFF",
                },
              },
            ],
          };
          this.optionsC={
            tooltip: {
              trigger: "item",
              formatter: function (res) {
                return (
                  '<div style="color:#fff;font-size:0.2rem;max-width:1.5rem;white-space:normal; word-break:break-all;overflow:hidden;">' +
                  res.name +
                  '</div><br/><span style="font-size:0.175rem;color:#FFF">数量：</span><span style="color:red;font-weight:bold;font-size:0.2rem">' +
                  res.value +
                  "</span>"
                );
              },
              backgroundColor:'rgba(0,0,0,0.5)',
              confine:true
            },
            series: [
              {
                name: "数量",
                type: "pie",
                radius: ["40%", "70%"],
                avoidLabelOverlap: false,
                labelLine: {
                  show: false,
                },
                data: this.pieThreeData,
                label: {
               show:false
                },
              },
            ],
          };
          this.optionsD={
            tooltip: {
              trigger: "item",
              formatter: function (res) {
                return (
                  '<div style="color:#fff;font-size:0.2rem;max-width:1.25rem;white-space:normal; word-break:break-all;overflow:hidden;">' +
                  res.name +
                  '</div><br/><span style="font-size:0.175rem;color:#FFF">数量：</span><span style="color:red;font-weight:bold;font-size:0.2rem">' +
                  res.value +
                  "</span>"
                );
              },
              backgroundColor:'rgba(0,0,0,0.5)',
              confine:true
            },
            series: [
              {
                name: "数量",
                type: "pie",
                radius: ["40%", "70%"],
                avoidLabelOverlap: false,
                labelLine: {
                  show: false,
                },
                data: this.pieFourData,
                label: {
               show:false
                },
              },
            ],
          };
        }
      },
    },
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 8%;
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
}
.content {
  height: 80%;
}
.child{
  width: 48%;
  height: 100%;
}
</style>