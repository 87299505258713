<template>
    <div id="compontent">
          <dv-border-box-6
      width="90%"
      height="90%"
      id="border"
      backgroundColor="RGBA(77, 144, 254,0.2)"
    >
    <div class="out">
          <table class="table-count" style="border-spacing: 0;
border-collapse: collapse;">
            <colgroup><col width="16%"><col width="28%"><col width="28%"><col width="28%"></colgroup>
            <thead><tr><th></th><th>国内专利申请</th><th>PCT申请</th><th>商标注册</th></tr></thead>
            <tbody>
              <tr><th>总量</th><td><b id="data1">61454</b>件</td><td><b id="data4">129</b>件</td><td><b id="data7">176869</b>件</td></tr>
              <tr><th>2020年</th><td><b id="data2">6251</b>件</td><td><b id="data5">6</b>件</td><td><b id="data8">31424</b>件</td></tr>
              <tr><th>增幅</th><td><b id="data3">-0.9</b>%</td><td><b id="data6">-33.3</b>%</td><td><b id="data9">37.5</b>%</td></tr>
            </tbody>
          </table>
    </div>
    </dv-border-box-6>
    </div>
</template>
<script>
export default {
    name:'tableView',

}
</script>
<style scoped>
    #compontent{
        width: 100%;
        height: 100%;
    }
    .out{
         width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
    }
    .title {
  height: 12%;
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
}
.content {
  height: 83%;
  display: flex;
  justify-content: space-around;
}
.table-count{
    width: 100%;
table-layout: fixed;
font-size: .15rem /* 12px -> .15rem */;
}
.table-count tbody th{
        background-color: #05304d;
    color: #f5b218;
}
.table-count th{
    background: #144061;
}
.table-count th, .table-count td{
    padding: .15rem /* 16px -> .2rem */;
text-align: center;
border: 1px solid #1e4e71;
}
.table-count td{
    font-size: .1875rem /* 15px -> .1875rem */;
}
</style>