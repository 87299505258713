<template>
  <div id="compontent" >
    <dv-border-box-10
      width="100%"
      height="100%"
      backgroundColor="RGBA(77, 144, 254,0.2)"
    >
    <!-- <dv-decoration-1 style="width:200px;height:50px;" /> -->
      <div style="width: 95%; height: 95%">
          <div class="btns">
          <div class="children">
            <div
              class="child"
              :class="{ childChoosen: flag == 0 }"
              @click="clickItem(0)"
            >
              <img :src="src1" alt="" />企业
            </div>
            <div
              class="child"
              :class="{ childChoosen: flag == 1 }"
              @click="clickItem(1)"
            >
              <img :src="src2" alt="" />大专院校
            </div>
            <div
              class="child"
              :class="{ childChoosen: flag == 2 }"
              @click="clickItem(2)"
            >
              <img :src="src3" alt="" />科研机构
            </div>
            <div
              class="child"
              :class="{ childChoosen: flag == 3 }"
              @click="clickItem(3)"
            >
              <img :src="src4" alt="" />机关团队
            </div>
          </div>
          <div class="numView">
            <dv-digital-flop
              :config="config"
              style="width: 100%; height: 100%"
            />
          </div>
        </div>
        <dv-percent-pond :config="configAA" style="width:100%;height:0.125rem;" />
        <map-echart
          :mapData="mapData"
          :data="needData"
          mapName="xinjiang"
          id="mapChatHH"
          :options="options"
          :isChange="true"
          style="width: 100%; height: 75%"
        />
        
      </div>
    </dv-border-box-10>
  </div>
</template>
<script>
import data from "../../../public/jsData/innovate";
import mapData from "../../../public/mapData/wulumuqiMap.json";
import mapEchart from "../../components/echarts/mapEchart.vue";
export default {
  components: { mapEchart },
  data() {
    return {
      mapData: "",
      needData: "",
      options: "",
      src1: require("../../../public/imgs/qiye.png"),
      src2: require("../../../public/imgs/xuexiao.png"),
      src3: require("../../../public/imgs/keyan.png"),
      src4: require("../../../public/imgs/zhengfu.png"),
      config: {
        number: [100],
        // toFixed: 2,
        content: "{nt}个",
      },
      flag: 0,
      intervalId: "",
      configAA:{
         value:0,
         borderWidth:1
      }
    };
  },
  mounted() {
    let me = this;
    this.mapData = mapData;
    this.needData = data.mapData;
    clearInterval(this.intervalId);
    this.interval();
    setInterval(function(){
        if(me.configAA.value>=100){
            me.configAA={value:0,borderWidth:1}
        }
        let value=me.configAA.value
        value=value+5
        me.configAA={value:value,borderWidth:1}
    },500)
  },
  methods: {
    clickItem(flag) {
      this.flag = flag;
      clearInterval(this.intervalId);
      this.interval();
    },
    interval() {
      let me = this;
      let i = this.flag;
      this.intervalId = setInterval(function () {
        if (i > 3) {
          i = 0;
        }
        me.flag = i;
        i++;
      }, 1500);
    },
  },
  watch: {
    needData: {
      handler: function (val) {
        if (val) {
          this.options = {
            tooltip: {
              trigger: "item",
              //鼠标放上去显示的数据和文字
              transitionDuration: 0,
              confine: true,
              formatter: (parms) => {
                // console.log(parms);
                return (
                  '<div style="text-align:center;font-size:0.175rem;font-weight:bold;border-bottom:#FFF 2px solid">' +
                  parms.data.name +
                  "</div>" +
                  "<div>" +
                  '<div style="color:#FFF;"><span style="font-size:0.175rem">企业：</span><span style="font-size:0.2rem;font-weight:bold">' +
                  parms.data.value[0] +
                  "次</span></div>" +
                  '<div style="color:#FFF;"><span style="font-size:0.175rem">大专院校：</span><span style="font-size:0.2rem;font-weight:bold">' +
                  parms.data.value[1] +
                  "次</span></div>" +
                  '<div style="color:#FFF;"><span style="font-size:0.175rem">科研机构：</span><span style="font-size:0.2rem;font-weight:bold">' +
                  parms.data.value[2] +
                  "次</span></div>" +
                  '<div style="color:#FFF;"><span style="font-size:0.175rem">机关团队：</span><span style="font-size:0.2rem;font-weight:bold">' +
                  parms.data.value[3] +
                  "次</span></div>" +
                  "</div>"
                );
              },
              backgroundColor: "rgba(0,0,0,0.6)",
              textStyle: {
                color: "rgba(255, 82, 17,1)",
              },
            },
            visualMap: {
              show: false,
              min: 0,
              max: 1500,
              text: ["High", "Low"],
              realtime: false,
              calculable: false,
              inRange: {
                color: ["lightskyblue", "yellow", "orangered"],
              },
            },
            // //在地图旁显示的数据
            series: [
              {
                name: "类型一",
                type: "map",
                mapType: "xinjiang",
                roam: false,
                itemStyle: {
                  normal: {
                    label: {
                      show: true,
                      textStyle: {
                        color: "rgba(0,0,0,0.5)",
                        fontSize: "0.15rem",
                      },
                    },
                    borderColor: "#FFF", //边界线颜色
                    borderWidth: 1, //边界线大小
                    shadowColor: "rgba(255,255,255,0)",
                    shadowBlur: 20,
                    // areaColor: "rgb(21, 41, 77)" //地区背景颜色
                  },
                  emphasis: {
                    //选中或者悬浮状态
                    label: {
                      show: true,
                      textStyle: {
                        color: "rgba(0,0,0,0.5)", //选中或者悬浮字体颜色
                        fontSize: "0.2rem",
                      },
                    },
                    areaColor: "#2b91b7", //选中或者悬浮区域颜色
                  },
                },
                label: {
                  normal: {
                    show: false,
                  },
                  emphasis: {
                    show: true,
                  },
                },
                data: this.needData,
              },
              {
                name: "Top 5",
                type: "effectScatter",
                coordinateSystem: "geo",
              },
            ],
          };
        }
      },
      deep: true,
      immediate: true,
    },
    flag: {
      handler: function (val) {
        if (val == 0) {
          this.src1 = require("../../../public/imgs/qiyeChoose.png");
          this.src2 = require("../../../public/imgs/xuexiao.png");
          this.src3 = require("../../../public/imgs/keyan.png");
          this.src4 = require("../../../public/imgs/zhengfu.png");
          this.config = {
            number: [5087],
            content: "{nt}家",
            style: {
              fontSize: 40,
            },
          };
        } else if (val == 1) {
          this.src1 = require("../../../public/imgs/qiye.png");
          this.src2 = require("../../../public/imgs/xuexiaoChoose.png");
          this.src3 = require("../../../public/imgs/keyan.png");
          this.src4 = require("../../../public/imgs/zhengfu.png");
          this.config = {
            number: [76],
            content: "{nt}家",
            style: {
              fontSize: 40,
            },
          };
        } else if (val == 2) {
          this.src1 = require("../../../public/imgs/qiye.png");
          this.src2 = require("../../../public/imgs/xuexiao.png");
          this.src3 = require("../../../public/imgs/keyanChoose.png");
          this.src4 = require("../../../public/imgs/zhengfu.png");
          this.config = {
            number: [87],
            content: "{nt}家",
            style: {
              fontSize: 40,
            },
          };
        } else if (val == 3) {
          this.src1 = require("../../../public/imgs/qiye.png");
          this.src2 = require("../../../public/imgs/xuexiao.png");
          this.src3 = require("../../../public/imgs/keyan.png");
          this.src4 = require("../../../public/imgs/zhengfuChoose.png");
          this.config = {
            number: [215],
            content: "{nt}家",
            style: {
              fontSize: 40,
            },
          };
        }
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 98%;
}
.btns {
  height: 25%;
  /* width: 100%; */
  display: flex;
}
.children {
  display: flex;
  width: 75%;
  justify-content: space-around;
  align-items: center;
}
.children img {
  width: 0.75rem /* 60px -> .75rem */;
  height: 0.75rem /* 60px -> .75rem */;
  margin: 0.0625rem /* 5px -> .0625rem */;
}
.child {
  margin: 0 0.125rem /* 10px -> .125rem */;
  padding: 0.0625rem /* 5px -> .0625rem */;
  flex: 1;
  border: #dbdbdb 0.05rem /* 4px -> .05rem */ solid;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 0.2rem /* 16px -> .2rem */;
  align-items: center;
  font-weight: bold;
  color: rgba(219, 219, 219, 0.4);
}
.childChoosen {
  color: #37e9ff;
  border: 0.05rem /* 4px -> .05rem */ #37e9ff solid;
}
.numView {
  width: 25%;
}
</style>