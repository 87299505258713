<template>
  <div id="compontent">
    <dv-border-box-6
      width="90%"
      height="90%"
      id="border"
      backgroundColor="RGBA(77, 144, 254,0.2)"
    >
      <div class="out">
        <div class="title">
          <span class="fixed-yellow">专利</span>申请人类型分布
        </div>
        <div class="content">
          <echart :options="options" :data="data" id="BarA" :isChange="true"/>
        </div>
      </div>
    </dv-border-box-6>
  </div>
</template>
<script>
import Echart from "../../components/echarts/echart.vue";
export default {
  name: "barChartClass",
  components: { Echart },
  data() {
    return {
      options: "",
      data: "",
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.data=[28204,3473,5521,6885,15058]
      this.options = {
    tooltip:{
      trigger:'item',
      backgroundColor:"rgba(255,255,255,0.5)",
      textStyle:{
          color:"#FFF"
      },
      formatter:function(res){
          return '<span style="font-size:0.175rem">'+res.name+'：</span>'+'<span style="font-size:0.2rem;font-weight:bold">'+res.data+'</span>'
        }
    },
    xAxis: {
        type: 'category',
        data: ['企业', '机关团体', '大专院校', '科研机构', '个人'],
        axisLabel:{
          fontSize:'0.15rem'
        }
    },
    yAxis: {
          nameTextStyle:{
            color:'#5c99c5',
            fontSize:"0.15rem"
        },
        name:"数量(件)",
        type: 'value',
        axisLabel:{
          fontSize:'0.15rem'
        }
    },
    series: [{
        data: this.data,
        type: 'line',
        lineStyle:{
            color:"rgba(145, 204, 117,1)"
        }
    }],
    grid:{
        top:40,
        x:70,
        x2:45,
        y2:"30px"
    },
};
    })
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 100%;
}
.out {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  height: 12%;
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
}
.content {
  height: 83%;
  display: flex;
  justify-content: space-around;
}
</style>