<template>
  <div id="compontent">
    <dv-border-box-10 width="85%" backgroundColor="RGBA(77, 144, 254,0.2)">
      <div
        style="
          height: 95%;
          width: 95%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        "
      >
        <div class="title">
          <span class="fixed-yellow">{{ title }}</span
          >产业专利运营情况
        </div>
        <div class="chart">
          <dv-active-ring-chart
            :config="config"
            style="width: 100%; height: 70%"
          />
          <div class="btns">
            <div
              class="item"
              @click="clickItem('XK')"
              :class="{ beenChoosen: flag == 'XK' }"
            >
              <dv-border-box-7>
                许可：
                <span>{{totalNum.XK}}</span>次
              </dv-border-box-7>
            </div>
            <div
              class="item"
              @click="clickItem('ZR')"
              :class="{ beenChoosen: flag == 'ZR' }"
            >
              <dv-border-box-7>
                转让：
                <span>{{totalNum.ZR}}</span>次
              </dv-border-box-7>
            </div>
            <div
              class="item"
              @click="clickItem('ZY')"
              :class="{ beenChoosen: flag == 'ZY' }"
            >
              <dv-border-box-7>
                质押：
                <span>{{totalNum.ZY}}</span>次
              </dv-border-box-7>
            </div>
          </div>
        </div>
      </div>
    </dv-border-box-10>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      needData: "",
      flag: "XK",
      config: {
        data: [],
        lineWidth: 20,
      },
      title:"",
      totalNum:{
        XK:"",
        ZR:"",
        ZY:""
      }
    };
  },
  mounted() {},
  methods: {
    clickItem(flag) {
      this.flag = flag;
    },
  },
  watch: {
    data: {
      handler: function (val) {
        this.totalNum=val.operation.total
        this.title=val.name
        this.needData = val.operation;
      },
      deep: true,
    },
    needData: {
      handler: function (val) {
        this.config = {
          data: val[this.flag],
          showOriginValue: true,
          digitalFlopUnit: "次",
        };
        // console.log(this.config)
      },
    },
    flag: {
      handler: function (val) {
        if (this.needData) {
          console.log(1);
          this.config = {
            data: this.needData[this.flag],
            showOriginValue: true,
            digitalFlopUnit: "次",
          };
        }
      },
    },
  },
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 100%;
}
.title {
  font-size: 0.25rem /* 20px -> .25rem */;
  line-height: 0.625rem /* 50px -> .625rem */;
  height: 20%;
}
.chart {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.btns {
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.item {
  height: 50%;
  width: 25%;
  text-align: center;
  font-size: 0.25rem /* 20px -> .25rem */;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.2);
}
.beenChoosen {
  background-color: rgba(255, 255, 255, 0.3);
}
</style>