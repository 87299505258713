
var tableItems = [
    ["01","新疆八一钢铁股份有限公司",2349],
    ["02","新疆大学",2257],
    ['03',"中国科学院新疆理化技术研究所",1614],
    ['04',"宝钢集团新疆八一钢铁有限公司",1608],
    ['05',"美克国际家居用品股份有限公司",1506],
    ['06',"新疆农业大学",1350],
    ['07',"新疆金风科技股份有限公司",1130],
    ['08',"国家电网公司",880],
    ['09',"中国石油集团西部钻探工程有限公司",830],
    ['10',"新疆维吾尔自治区人民医院",818],
    [11,"中国科学院新疆生态与地理研究所",767],
    [12,"新疆医科大学第一附属医院",621],
    [13,"国家电网有限公司",569],
    [14,"新特能源股份有限公司",470],
    [15,"新疆工程学院",459],
    [16,"新疆农业科学院农业机械化研究所",404],
    [17,"新疆众和股份有限公司",370],
    [18,"国网新疆电力有限公司电力科学研究院",364],
    [19,"新疆医科大学",354],
    [20,"特变电工新疆新能源股份有限公司",334],
    ]
    
    
    //zuo侧授权量
    var displayname =	 ['商标注册量','专利授权量'];
    var displayvalue1 =	['14096','14761','17527','22309','31400'];
    var displayvalue2 =	['4365','5195','6352','5983','7642'];
    var yearValue =	['2016年','2017年','2018年','2019年','2020年'];
    
    //右侧专利类型饼图
     var zllxdata =	['发明','新型','外观'];
     var zllxdata1 =	['111','30135','7852'];
    
    
    
    //右侧国民经济分类饼图
     var gmjjdata =	['农、林、牧、渔业','采矿业','制造业','居民服务、修理和其他服务业'];
     var gmjjdata1 =	[1205,812,32530,2600];
     var gmjjdataWG =	['农副食品加工','酒、饮料和精制茶制造业','烟草制品业'];
     var gmjjdataWGValue =	[946,854,46];
    
    
    
    //左下角图使用数据
    var serierName = ['许可','转让','质押']	
    var left1Value = 	['1066','10360','318'];
    var left1ValuePer = 	['9.1%','88.2%','2.7%'];
    
    
    //右侧第二行第二个柱图
    var leftname = 	['企业','机关团体','大专院校','科研机构','个人']
    var leftvalue = 	['','3473','5521','6885','15058']
    
    
    
    //以下为专利数据
    //地图数据-专利	
    let mapData=[
        {name:'天山区',value:[1,1,4050]},
        {name:"沙依巴克区",value:[1,1,4100]},
        {name:"水磨沟区",value:[1,1,1441]},
        {name:"新市区",value:[1,1,11650]},
        {name:"头屯河区",value:[1,1,2250]},
        {name:"米东区",value:[1,1,1250]},
        {name:'达坂城区',value:[1,1,183]},
        {name:"乌鲁木齐县",value:[1,1,225]}
    ]
    
        
    //地图数据-商标	
    
        let mapDataSB=[
            {name:'新市区',value:[1,1,15035]},
            {name:"天山区",value:[1,1,48901]},
            {name:"沙依巴克区",value:[1,1,24024]},
            {name:"水磨沟区",value:[1,1,10853]},
            {name:"达坂城区",value:[1,1,622]},
            {name:"米东区",value:[1,1,6909]},
            {name:"乌鲁木齐县",value:[1,1,2172]},
            {name:"头屯河区",value:[1,1,3588]}
        ]
    
    //地图数据-地标
     var mapDataDB = [	
        {name:'新市区',value:[1,1,3]},
        {name:"天山区",value:[1,1,0]},
        {name:"沙依巴克区",value:[1,1,0]},
        {name:"水磨沟区",value:[1,1,0]},
        {name:"达坂城区",value:[1,1,0]},
        {name:"米东区",value:[1,1,0]},
        {name:"乌鲁木齐县",value:[1,1,0]},
        {name:"头屯河区",value:[1,1,0]}
        ]
    
    
    //地图数据-电路	
     var mapDataDL = [	
        {name:'新市区',value:[1,1,3]},
        {name:"天山区",value:[1,1,0]},
        {name:"沙依巴克区",value:[1,1,0]},
        {name:"水磨沟区",value:[1,1,0]},
        {name:"达坂城区",value:[1,1,0]},
        {name:"米东区",value:[1,1,0]},
        {name:"乌鲁木齐县",value:[1,1,0]},
        {name:"头屯河区",value:[1,1,0]}
        ]
    
    
    
    var color1Divide = "乌鲁木齐市";
    var color2Divide = "克拉玛依市";
    var color3Divide = "吐鲁番市";
    var color1Value = "#DD3F48";
    var color2Value = "#CDB87A";
    var color3Value = "#72B6DB";
    
    
    export default{
        tableItems,
        mapDataDL,
        mapDataDB,
        mapDataSB,
        mapData,
        leftname,
        leftvalue,
        displayname,
        displayvalue1,
        displayvalue2,
        yearValue,
        zllxdata,
        zllxdata1,
        gmjjdata,gmjjdata1,
        gmjjdataWG,
        gmjjdataWGValue
    }
   