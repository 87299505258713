<template>
  <div id="compontent">
    <dv-border-box-7 backgroundColor="rgba(93, 93, 95,0.3)">
      <div class="out">
          <div class="title">
              <span class="fixed-yellow">运营</span>专利国民经济领域分布（外观设计）
          </div>
          <div class="content">
              <echart :options="options" id="pieChartGH" :data="needData" :isChange="true" />
          </div>
      </div>
    </dv-border-box-7>
  </div>
</template>
<script>
import data from '../../../public/jsData/patent'
import echart from '../../components/echarts/echart.vue';
export default {
  components: { echart },
    name:"rightPie",
    data() {
        return {
            needData:"",
            options:""
        }
    },
    mounted(){
        this.needData=data.gmjjdataWG
    },
    watch:{
        needData:{
            handler:function(val){
                this.options={
          tooltip: {
            trigger: "item",
            backgroundColor: "rgba(0,0,0,0.2)",
            textStyle: {
              color: "#FFF",
            },
            position: function (point, params, dom, rect, size) {
              return {
                // left:(size.viewSize[0]-size.contentSize[0])/2,
                // top:(size.viewSize[1]-size.contentSize[1])/2
                left: point[0] + 10,
                top: point[1] + 10,
              };
            },
            confine: true,
            formatter: function (res) {
              return (
                '<span style="font-size:0.175rem">' +
                res.data.name +
                "<br/>" +
                "数量：</span>" +
                '<span style="font-size:0.2rem;font-weight:bold">' +
                res.data.value +
                "</span>"
              );
            },
          },
          series: [
            {
              selecteMode: "single",
              name: "访问来源",
              type: "pie",
              radius: ["80%", "50%"],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 5,
                borderColor: "#fff",
                borderWidth: 0,
              },
              label: {
                show: false,
                position: "center",
              },
              labelLine: {
                show: false,
              },
              data: this.needData,
            },
          ],
        };
            }
        }
    }
};
</script>
<style scoped>
#compontent {
  width: 100%;
  height: 98%;
}
.out{
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.title{
    height: 10%;
    font-size: .225rem /* 18px -> .225rem */;
    font-weight: bold;
    line-height: .5rem /* 40px -> .5rem */;
}
.content{
    height: 88%;
}
</style>